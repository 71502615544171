import axios from "../login/api/axios";

const GENERATE_URL = "/generate";
const GENERATE_OVERVIEW_URL = "/generateOverview";
const GENERATE_OVERVIEW_ID_URL = "/generateOverviewById";
/*
export function GenerateOfferte(id:number):any {
    const accessToken = sessionStorage.getItem('accessToken')
    const project = new Promise ((resolve, reject) => {
        //console.log("opening project with id: ", id)
        axios.get(
            GENERATE_URL,
            {
            headers: { 
                'Authorization' : 'Bearer ' + accessToken,
                "Content-Type": "application/json",
            },
            withCredentials: true,
            params: {projid:id}
            },
        )
        .then(res => {
        if (res) {
            //console.log(res)
            resolve (res.data)
        } else {
            reject ("DB No modules found")
        }
        })
        .catch(error => {
            console.log ("Error: DB No modules found: " + error)
        })
    }); 
    return project
}
*/
export function GenOff2(offerteItems: any, noSaItems:any): any {
  const accessToken = sessionStorage.getItem("accessToken");
  const userid = sessionStorage.getItem("userid");
  const customerId = sessionStorage.getItem("eindklantId");
  const fileName = sessionStorage.getItem("bestandsnaam");
  const ItemsFromDatabase = new Promise((resolve, reject) => {
    axios
      .get(GENERATE_URL, {
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
        withCredentials: true,
        params: { items: offerteItems, nosaitems: noSaItems,userid: userid, customerId: customerId },
        responseType: "blob",
      })

      .then((res) => {
        if (res) {
          const href = window.URL.createObjectURL(res.data);

          const anchorElement = document.createElement("a");
          anchorElement.href = href;
          anchorElement.download = fileName + " - Offerte";
          anchorElement.click();

          resolve("Offerte downloaded");
        } else {
          reject("DB No items found");
        }
      })

      .catch((error) => {
        console.log("Error: DB No items found: " + error);
      });
  });

  return ItemsFromDatabase;
}

export function GenOverview(items: any): any {
  const accessToken = sessionStorage.getItem("accessToken");
  const userid = sessionStorage.getItem("userid");
  const customerId = sessionStorage.getItem("eindklantId");

  const ItemsFromDatabase = new Promise((resolve, reject) => {
    axios
      .get(GENERATE_OVERVIEW_URL, {
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
        withCredentials: true,
        params: { items: items, userid: userid, customerId: customerId },
        responseType: "blob",
      })

      .then((res) => {
        if (res) {
          const href = window.URL.createObjectURL(res.data);

          const anchorElement = document.createElement("a");
          anchorElement.href = href;
          anchorElement.download = "overview";
          anchorElement.click();

          resolve("Overzicht downloaded");
        } else {
          reject("DB No items found");
        }
      })

      .catch((error) => {
        console.log("Error: DB No items found: " + error);
      });
  });

  return ItemsFromDatabase;
}

export function GenOverviewById(projectid: any): any {
  const accessToken = sessionStorage.getItem("accessToken");
  const userid = sessionStorage.getItem("userid");
  const customerId = sessionStorage.getItem("eindklantId");
  const fileName = sessionStorage.getItem("bestandsnaam");

  const ItemsFromDatabase = new Promise((resolve, reject) => {
    axios
      .get(GENERATE_OVERVIEW_ID_URL, {
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
        withCredentials: true,
        params: {
          projectId: projectid,
          userid: userid,
          customerId: customerId,
        },
        responseType: "blob",
      })

      .then((res) => {
        if (res) {
          const href = window.URL.createObjectURL(res.data);

          const anchorElement = document.createElement("a");
          anchorElement.href = href;
          anchorElement.download = fileName + " - Overzicht";
          anchorElement.click();

          resolve("Overzicht downloaded");
        } else {
          reject("DB No items found");
        }
      })

      .catch((error) => {
        console.log("Error: DB No items found: " + error);
      });
  });

  return ItemsFromDatabase;
}
