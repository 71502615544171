import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import "./settingsOptions.css";

interface MyState {}
interface MyProps {
  onPakettenChanged: (t: any) => any;
  onREL04_08Changed: (t: any) => any;
  paketten: boolean;
  rel04_08: boolean;
}

class SettingsOptions extends Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  handlePakettenCheck = (eventKey: any) => {
    this.props.onPakettenChanged(eventKey.target.checked);
  };

  handleRel04_08Check = (eventKey: any) => {
    this.props.onREL04_08Changed(eventKey.target.checked);
  };

  render() {
    return (
      <>
        <Form.Check
          type="checkbox"
          className="formCheck1"
        >
          <Form.Check.Input
            type="checkbox"
            defaultChecked={this.props.paketten}
            onChange={this.handlePakettenCheck}
          />
          <Form.Check.Label className="formLabel1" >
            Gebruik paketten
          </Form.Check.Label>
        </Form.Check>

        <Form.Check
          type="checkbox"
          className="formCheck1"
        >
          <Form.Check.Input
            type="checkbox"
            defaultChecked={this.props.rel04_08}
            onChange={this.handleRel04_08Check}
          />
          <Form.Check.Label className="formLabel1" >
            Gebruik enkel REL04SA modules (geen REL08)
          </Form.Check.Label>
        </Form.Check>
      </>
    );
  }
}

export default SettingsOptions;
