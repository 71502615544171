import React, { Component } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { GetUserInfo, UpdateUserInfo } from "../database/userInfo";
import "./klantgegevens.css";
interface MyState {
  id: any;
  naam: string;
  voornaam: string;
  firma: string;
  straat: string;
  huisnummer: string;
  postcode: string;
  gemeente: string;
  land: string;
  telefoon: string;
  btw: string;
}
interface MyProps {}

class Klantgegevens extends Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      id: null,
      naam: "",
      voornaam: "",
      firma: "",
      straat: "",
      huisnummer: "",
      postcode: "",
      gemeente: "",
      land: "",
      telefoon: "",
      btw: "",
    };
    this.getUser1 = this.getUser1.bind(this);
  }

  naam = "1";

  async getUser1() {
    GetUserInfo().then((data: any) => {
      const userInfo = data.success;
      if (this.state.naam !== userInfo.naam) {
        this.setState({ id: userInfo.id });
        this.setState({ naam: userInfo.naam });
        this.setState({ voornaam: userInfo.voornaam });
        this.setState({ firma: userInfo.firma });
        this.setState({ straat: userInfo.straat });
        this.setState({ huisnummer: userInfo.huisnummer });
        this.setState({ postcode: userInfo.postcode });
        this.setState({ gemeente: userInfo.gemeente });
        this.setState({ land: userInfo.land });
        this.setState({ telefoon: userInfo.telefoon });
        this.setState({ btw: userInfo.btw });
      }
    });
  }

  async updateUser(
    id: any,
    naam: string,
    voornaam: string,
    firma: string,
    straat: string,
    huisnr: string,
    postcode: string,
    gemeente: string,
    land: string,
    telefoon: string,
    btwnr: string
  ) {
    //const created = await HandleNewCustomer(
    //debugger
    const created = await UpdateUserInfo(
      id,
      naam,
      voornaam,
      firma,
      straat,
      huisnr,
      postcode,
      gemeente,
      land,
      telefoon,
      btwnr
    );
    console.log("rp: ", created);
    //this.getCustomers();
    //this.setState({ newCustomer: false });
  }

  submitHandler = (e: any) => {
    //debugger
    e.preventDefault();

    const id = e.currentTarget.id.value;
    const naam = e.currentTarget.naam.value;
    const voornaam = e.currentTarget.voornaam.value;
    const firma = e.currentTarget.firma.value;
    const straat = e.currentTarget.straat.value;
    const huisnr = e.currentTarget.huisnummer.value;
    const postcode = e.currentTarget.postcode.value;
    const gemeente = e.currentTarget.gemeente.value;
    const land = e.currentTarget.land.value;
    const telefoon = e.currentTarget.telefoon.value;
    const btwnr = e.currentTarget.btw.value;

    this.updateUser(
      id,
      naam,
      voornaam,
      firma,
      straat,
      huisnr,
      postcode,
      gemeente,
      land,
      telefoon,
      btwnr
    );
  };

  render() {
    this.getUser1();
    return (
      <>
        <Form onSubmit={this.submitHandler}>
          <Form.Control type="hidden" defaultValue={this.state.id} name="id" />

          <Row>
            <Col>
              <Form.Label className="customer-formLabel">Naam</Form.Label>
              <Form.Control
                type="text"
                defaultValue={this.state.naam}
                name="naam"
                className="customer-form"
              />
              <Form.Text className="customer-form"></Form.Text>
            </Col>

            <Col>
              <Form.Label className="customer-formLabel">Voornaam</Form.Label>
              <Form.Control
                type="text"
                defaultValue={this.state.voornaam}
                name="voornaam"
                className="customer-form"
              />
              <Form.Text className="text-muted"></Form.Text>
            </Col>
          </Row>
          <Row>
            <Col>
            <Form.Label className="customer-formLabel">Firma</Form.Label>
            <Form.Control
              type="text"
              defaultValue={this.state.firma}
              name="firma"
              className="customer-form"
            />
            <Form.Text className="text-muted"></Form.Text>
            </Col>
          </Row>
          <Row>
            <Col >
              <Form.Label className="customer-formLabel">Straat</Form.Label>
              <Form.Control
                type="text"
                defaultValue={this.state.straat}
                name="straat"
                className="customer-form"
                //style={{width: "20x;"}}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Col>
            <Col>
              <Form.Label className="customer-formLabel">
                Huisnummer
              </Form.Label>
              <Form.Control
                type="text"
                defaultValue={this.state.huisnummer}
                name="huisnummer"
                className="customer-form"
                //style={{width: "20x;"}}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Col>
          </Row>

          <Row>
            <Col>
            <Form.Label className="customer-formLabel">Postcode</Form.Label>
          <Form.Control
            type="text"
            defaultValue={this.state.postcode}
            name="postcode"
            className="customer-form"
          />
          <Form.Text className="text-muted"></Form.Text>
            </Col>
            <Col>
            <Form.Label className="customer-formLabel">Gemeente</Form.Label>
          <Form.Control
            type="text"
            defaultValue={this.state.gemeente}
            name="gemeente"
            className="customer-form"
          />
          <Form.Text className="text-muted"></Form.Text>
            </Col>
          </Row>
          

          

          <Form.Label className="customer-formLabel">Land</Form.Label>
          <Form.Control
            type="text"
            defaultValue={this.state.land}
            name="land"
            className="customer-form"
          />
          <Form.Text className="text-muted"></Form.Text>

          <Form.Label className="customer-formLabel">Telefoon</Form.Label>
          <Form.Control
            type="text"
            defaultValue={this.state.telefoon}
            name="telefoon"
            className="customer-form"
          />
          <Form.Text className="text-muted"></Form.Text>

          <Form.Label className="customer-formLabel">BTW nr</Form.Label>
          <Form.Control
            type="text"
            defaultValue={this.state.btw}
            name="btw"
            className="customer-form"
          />
          <Form.Text className="text-muted"></Form.Text>

          <Button variant="primary" type="submit">
            Bewaar
          </Button>
        </Form>
      </>
    );
  }
}

export default Klantgegevens;
