import { useContext, useState } from "react";
import axios from "../login/api/axios"
import AuthContext from "../login/context/AuthProvider";
//import {AuthProvider} from "../login/context/AuthProvider"

const ITEMS_URL = '/items';


export interface itemsFromDatabase  {
    success: [
      {
        id: number;
        nodeid: string;
        label: string;
        type: number;
        parent: number;
        typeIcon: number;
        busCmoduleTypeonsumption: number;
        createdAt: string;
        updatedAt: string;
      }
    ]
  }

  
export async function GetOutputsFromDatabase(type:string) {
  //const accessToken = useContext(AuthContext)

  //const accessToken = null
  //const accessToken = AuthProvider.
  //const { auth } = useContext(AuthContext)
  //const accessToken = useState()
  //const auth = useContext(AuthContext);
  const accessToken = sessionStorage.getItem('accessToken')

  //console.log("AT: ", accessToken)

  const ItemsFromDatabase = new Promise<itemsFromDatabase>((resolve, reject) => {
    axios.get(
      ITEMS_URL,
      {
        headers: { 
          'Authorization' : 'Bearer ' + accessToken,
          "Content-Type": "application/json",
        },
        withCredentials: true,
        params: {type:type}
      }
    )

    .then(res => {
      if (res) {
        resolve (res.data)
      } else {
        reject ("DB No items found")
      }
    })
    .catch(error => {
      console.log ("Error: DB No items found: " + error)
    })
  }); 

    const t = await ItemsFromDatabase
    //console.log(t)
    return t
} 
