import * as interfaces from "./sharedInterfaces";
import * as myTreeIcons from "../../images/icons/treeicons";
import * as functions from "./sharedFunctions";

// Get outputs from woningtree an calculate offerte/kast items

function calculateModules(
  moduleType: string,
  aantal: number,
  onlySa: boolean,
  rel04_08: boolean
) {
  if (
    moduleType === "ROL" ||
    moduleType === "hand" ||
    moduleType === "fan" ||
    moduleType === "stopc"
  ) {
    moduleType = "REL";
  }
  const module = functions.getModule(moduleType, onlySa);

  const moduleParameters = module.retModules;

  let modulesMap = new Map<string, object>();
  let outp = {};

  let modulesO: Array<{
    outputs: number;
    type: string;
    counter: number;
    vrij: number;
    modType: string;
    modId: number;
    adresses: number;
    usedAddresses: number;
    //totBusCons: number,
    //busc: number,
  }> = [];
  // moduleType: REL
  moduleParameters.forEach((mod) => {
    // if (rel04_08 === true) //Enkel REL04SA
    //debugger
    if (moduleType === "REL") {
      if (rel04_08 === false) {
        let modi = {
          outputs: mod.outputs,
          type: mod.name,
          counter: 0,
          vrij: 0,
          modType: moduleType,
          modId: mod.id,
          adresses: mod.adresses,
          usedAddresses: 0,
          //totBusCons: 0,
          // busc: mod.busConsumption
        };
        modulesO.push(modi);
      } else {
        if (mod.name === "REL04SA") {
          let modi = {
            outputs: mod.outputs,
            type: mod.name,
            counter: 0,
            vrij: 0,
            modType: moduleType,
            modId: mod.id,
            adresses: mod.adresses,
            usedAddresses: 0,
            // totBusCons: 0,
            // busc: mod.busConsumption
          };
          modulesO.push(modi);
        }
      }
    } else {
      //&& rel04_08 === false) {
      let modi = {
        outputs: mod.outputs,
        type: mod.name,
        counter: 0,
        vrij: 0,
        modType: moduleType,
        modId: mod.id,
        adresses: mod.adresses,
        usedAddresses: 0,
        // totBusCons: 0,
        // busc: mod.busConsumption
      };
      modulesO.push(modi);
    }
  });

  let modc = Object.keys(modulesO).length;

  let newo = aantal;
  //vrij = 0

  // modc - Aantal modules voor dit type
  // modulesO - Array van modules voor dit ype
  // counter, modId, modType, outputs, type, vrij
  // Here we update the counters for each modulesO
  // ToDo:
  for (let i = 0; i < modc; i++) {
    //debugger
    let currOutputs = modulesO[i].outputs;
    let nextOutputs = 0;
    //debugger
    // First mod
    if (i < modc - 1) {
      nextOutputs = modulesO[i + 1].outputs;
    }
    //debugger
    let teller1 = Math.floor(newo / currOutputs);
    let oversc = newo - teller1 * currOutputs;

    if (i === 0 && teller1 >= 1 && oversc !== 0) {
      modulesO[i].counter = teller1;
      modulesO[i].vrij = 0;
      modulesO[i].usedAddresses =
        modulesO[i].counter * modulesO[i].outputs * modulesO[i].adresses -
        modulesO[i].vrij * modulesO[i].adresses;
      //modulesO[i].totBusCons = (modulesO[i].counter * )
      // modulesO[i].totBusCons = modulesO[i].busc * modulesO[i].counter
    }

    if (oversc < currOutputs && oversc > nextOutputs) {
      modulesO[i].counter++;
      modulesO[i].vrij = currOutputs - oversc;
      modulesO[i].usedAddresses =
        modulesO[i].counter * modulesO[i].outputs * modulesO[i].adresses -
        modulesO[i].vrij * modulesO[i].adresses;
      //modulesO[i].totBusCons = modulesO[i].busc * modulesO[i].counter
      //modulesO[i].totoutp = modulesO[i].totoutp
      break;
    } else if (oversc === currOutputs) {
      modulesO[i].counter++;
      modulesO[i].vrij = currOutputs - oversc;
      modulesO[i].usedAddresses =
        modulesO[i].counter * modulesO[i].outputs * modulesO[i].adresses -
        modulesO[i].vrij * modulesO[i].adresses;
      //modulesO[i].totBusCons = modulesO[i].busc * modulesO[i].counter
    }

    if (i === 0 && oversc === 0) {
      modulesO[i].counter = Math.floor(aantal / currOutputs);
      modulesO[i].vrij = 0;
      modulesO[i].usedAddresses =
        modulesO[i].counter * modulesO[i].outputs * modulesO[i].adresses -
        modulesO[i].vrij * modulesO[i].adresses;
      //modulesO[i].totBusCons = modulesO[i].busc * modulesO[i].counter
      break;
    } else if (oversc === 0) {
      modulesO[i].counter++;
      modulesO[i].vrij = 0;
      //modulesO[i].totoutp = currOutputs
      modulesO[i].usedAddresses =
        modulesO[i].counter * modulesO[i].outputs * modulesO[i].adresses -
        modulesO[i].vrij * modulesO[i].adresses;
      //modulesO[i].totBusCons = modulesO[i].busc * modulesO[i].counter
      break;
    }
  }

  modulesO.forEach((mod) => {
    if (mod.counter > 0) {
      outp = {
        used: mod.counter,
        vrij: mod.vrij,
        modType: mod.modType,
        modId: mod.modId,
        usedAddresses: mod.usedAddresses,
        // usedBusConsumption: mod.totBusCons
      };
      modulesMap.set(mod.type, outp);
    }
  });

  //console.log(modulesO)

  return modulesMap;

  //console.log(totop - aantal)
  //return modulesMap;
}

// komt van overzicht.tsx
export function getModules(
  arr: myTreeIcons.kamerItem,
  onlySa: boolean,
  rel04_08: boolean
) {
  //console.log("OVERZICHT ARR: ", arr)
  //console.log(arr)
  // returns a map:
  // //[key: "REL", value(map): [{key: REL02SA, value: 1} , {key:REL01SA, value: 1}]]

  let modulesMap = new Map<string, number>();

    const test = (item: myTreeIcons.kamerItem) => {
    item.children?.map((item) => {
      let currentCounter: number = 0;
      let newCounter: number = 0;
      //item.icon.key = database value module type (REL, DIM)

      if (item.icon.key !== "woning") {
        let treeiconkey = item.icon.key;

        if (
          treeiconkey === "ROL" ||
          treeiconkey === "hand" ||
          treeiconkey === "fan" ||
          treeiconkey === "stopc"
        ) {
          treeiconkey = "REL";
        }
        //console.log(item)
        //debugger
        // RELROL !!!!!!!!!!

        // item.icon.key = REL, DIM
        currentCounter = modulesMap.get(treeiconkey)!;
        newCounter = currentCounter + 1;

        if (newCounter) {
          modulesMap.set(treeiconkey, newCounter);
        } else {
          modulesMap.set(treeiconkey, 1);
        }
      }
      if (item.children) {
        //console.log(item.children?.length)

        if (item.children?.length >> 0) {
          test(item);
        }
      }
    });
  };

  test(arr);

  //console.log
  //console.log("MODMAP: ", modulesMap)
  // [{REL: 3}, ...]
  //debugger
  let iMap = new Map<string, object>();
  modulesMap.forEach((value, key) => {
    //console.log(key, value)
    //console.log (typeof(calculateModules(key, value)))
    // key = REL, DIM, ...
    // value = {REL04SA, 2}
    iMap.set(key, calculateModules(key, value, onlySa, rel04_08));
  });

  return iMap;
}

function getExtMod(id: number) {
  // Find module in Extended database
  const dbExtendedMods: interfaces.ExtendedModuleTypes[] = JSON.parse(
    sessionStorage.getItem("DbExtendedModuleTypes") || "{}"
  );
  // console.log("dbExtendedMods: ", dbExtendedMods)

  let retmod = {};
  dbExtendedMods.map((mod: interfaces.ExtendedModuleTypes) => {
    if (mod.module_id === id) {
      //console.log("AA: ", mod)
      retmod = mod;
    }
  });
  //console.log ("RETMOD/ ", retmod)
  return retmod;
}

function findModId(type: string) {
  const dbMods: interfaces.ModuleTypes[] = JSON.parse(
    sessionStorage.getItem("DbModules") || "{}"
  );
  // Find module in modules database
  //console.log("DBMods: ", dbMods)
  let retMod = {};

  if (dbMods) {
    dbMods.map((mod: interfaces.ModuleTypes) => {
      if (mod.name === type) {
        const modret = getExtMod(mod.id);
        retMod = modret;
      }
    });
  }
  // console.log ("findmod/ ", retMod)
  return retMod;
}

export function getModuleParameters(type: string) {
  const dbMods: interfaces.ModuleTypes[] = JSON.parse(
    sessionStorage.getItem("DbModules") || "{}"
  );
  // Find module in modules database
  //console.log("DBMods: ", dbMods)
  let retMod = 0;

  let rMod = {
    busConsumption: 0,
    parentModule: 0,
    offCat: 0,
    adresses: 0,
    sa: 0,
    pakket: 0,
  };



  if (dbMods) {
    dbMods.map((mod: interfaces.ModuleTypes) => {
      
      if (mod.name === type) {
        
        const buscons = mod.busConsumption;
        retMod = buscons;
        rMod.busConsumption = mod.busConsumption;
        rMod.parentModule = mod.parentModule;
        rMod.offCat = mod.offCat;
        rMod.adresses = mod.adresses;
        rMod.sa = mod.sa;
        rMod.pakket = mod.pakket;
      }
    });
  }
  //console.log ("findmod/ ", rMod)
  return rMod;
}

export function getModuleParametersByType(type: string) {
  const dbMods: interfaces.ModuleTypes[] = JSON.parse(
    sessionStorage.getItem("DbModules") || "{}"
  );
  // Find module in modules database
  //console.log("DBMods: ", dbMods)
  let retMod = 0;

  let rMod = {
    busConsumption: 0,
    parentModule: 0,
    offCat: 0,
    adresses: 0,
    sa: 0,
    pakket: 0,
  };

  if (dbMods) {
    dbMods.map((mod: interfaces.ModuleTypes) => {
      if (mod.type === type) {
        const buscons = mod.busConsumption;
        retMod = buscons;
        rMod.busConsumption = mod.busConsumption;
        rMod.parentModule = mod.parentModule;
        rMod.offCat = mod.offCat;
        rMod.adresses = mod.adresses;
        rMod.sa = mod.sa;
        rMod.pakket = mod.pakket;
      }
    });
  }
  //console.log ("findmod/ ", rMod)
  return rMod;
}

function getCtds() {
  const dbMods: interfaces.ModuleTypes[] = JSON.parse(
    sessionStorage.getItem("DbModules") || "{}"
  );
  // Find module in modules database
  //console.log("DBMods: ", dbMods)
  let ctds = [{}];

  if (dbMods) {
    dbMods.map((mod: interfaces.ModuleTypes) => {
      if (mod.name.includes("CTD")) {
        //const buscons = mod.busConsumption
        ctds.push(mod);
      }
    });
  }
  // console.log ("findmod/ ", retMod)
  return ctds;
}

function getExpantion() {
  const dbMods: interfaces.ModuleTypes[] = JSON.parse(
    sessionStorage.getItem("DbModules") || "{}"
  );
  // Find module in modules database
  //console.log("DBMods: ", dbMods)
  let exp = {};

  if (dbMods) {
    dbMods.map((mod: interfaces.ModuleTypes) => {
      if (mod.name.includes("EXP15")) {
        //const buscons = mod.busConsumption
        exp = mod;
      }
    });
  }
  // console.log ("findmod/ ", retMod)
  return exp;
}

export interface mods1 {
  module: string;
  aantal: number;
  prijs: number;
  vrij: number;
  modType: string;
  busCons: number;
  parentModule: number;
  modId: number;
  offCat: number;
  adresses: number;
  sa: number;
  pakket: number;
  totadres: number;
}

export interface mods2 {
  module: string;
  aantal: number;
  prijs: number;
  vrij: number;
  modType: string;
  modId: number;
  sa: number;
  pakket: number;
}

interface modsArray {
  mods: Array<mods1>;
}

export interface modsArray2 {
  mods: Array<mods2>;
}

function addCtds(adresCounter: number, modCounter: number, ctds = [{}]) {
  // Todo 2x executed??
  //debugger

/*
  for (let i = 0; i < ctdsNodig; i++) {
    let ctd = "";
    let retCtd: any = {};
    let expantion = false;
    let retmodcounter = 0;
    //debugger
    // CTD10
    if (modCounter < 11) {
      ctd = "CTD10";
      ctds.map((m: any) => {
        if (m.name === "CTD10") {
          retCtd = m;
        }
      });
      const modulesOver = 10 - modCounter;
      if (modulesOver > 0) {
        retCtd.vrij = modulesOver;
      } else {
        retCtd.vrij = 0;
      }

      retmodcounter = 0;
      expantion = false;
    } else if (modCounter < 26) {
      ctd = "CTD10";
      ctds.map((m: any) => {
        if (m.name === "CTD10") {
          retCtd = m;
        }
      });
      const modulesOver = 25 - modCounter;
      if (modulesOver > 0) {
        retCtd.vrij = modulesOver;
      } else {
        retCtd.vrij = 0;
      }
      expantion = true;
      retmodcounter = 0;
    } else if (modCounter < 41) {
      ctd = "CTD40";
      ctds.map((m: any) => {
        if (m.name === "CTD40") {
          retCtd = m;
        }
      });
      const modulesOver = 40 - modCounter;
      if (modulesOver > 0) {
        retCtd.vrij = modulesOver;
      } else {
        retCtd.vrij = 0;
      }
      expantion = false;
    } else if (modCounter < 56) {
      ctd = "CTD40";
      ctds.map((m: any) => {
        if (m.name === "CTD40") {
          retCtd = m;
        }
      });
      const modulesOver = 55 - modCounter;
      if (modulesOver > 0) {
        retCtd.vrij = modulesOver;
      } else {
        retCtd.vrij = 0;
      }
      expantion = true;
      retmodcounter = 0;
    } else if (modCounter >= 56 && modCounter <= 150) {
      ctd = "CTDmax";
      ctds.map((m: any) => {
        if (m.name === "CTDmax") {
          retCtd = m;
        }
      });
      const modulesOver = 150 - modCounter;
      //debugger
      if (modulesOver > 0) {
        retCtd.vrij = modulesOver;
      } else {
        retCtd.vrij = 0;
      }
      expantion = false;
    } else if (modCounter > 150) {
      ctd = "CTDmax";
      //debugger
      ctds.map((m: any) => {
        if (m.name === "CTDmax") {
          retCtd = m;
        }
      });
      expantion = false;
      //outputs / ctdoutpust
      let x = Math.floor(modCounter / 150);
      const y = modCounter % 150;

      retmodcounter = modCounter - 150;
      // if (y!=0){x=x+1}
      //if (y!=0){x++}
      const modulesOver = 150 * Math.ceil(modCounter / 150) - modCounter;

      if (modulesOver > 0) {
        retCtd.vrij = modulesOver;
      } else {
        retCtd.vrij = 0;
      }
      if (modulesOver >= 56 && modulesOver <= 150) {
        retCtd.vrij = 0;
      }

      if (retmodcounter < 0) {
        retmodcounter = 0;
      }
    }
  }
*/
  let ctd = "";
  let retCtd: any = {};
  let expantion = false;
  let retmodcounter = 0;
  //debugger
  // CTD10
  if (modCounter < 11) {
    ctd = "CTD10";
    ctds.map((m: any) => {
      if (m.name === "CTD10") {
        retCtd = m;
      }
    });
    const modulesOver = 10 - modCounter;
    if (modulesOver > 0) {
      retCtd.vrij = modulesOver;
    } else {
      retCtd.vrij = 0;
    }

    retmodcounter = 0;
    expantion = false;
  } else if (modCounter < 26) {
    ctd = "CTD10";
    ctds.map((m: any) => {
      if (m.name === "CTD10") {
        retCtd = m;
      }
    });
    const modulesOver = 25 - modCounter;
    if (modulesOver > 0) {
      retCtd.vrij = modulesOver;
    } else {
      retCtd.vrij = 0;
    }
    expantion = true;
    retmodcounter = 0;
  } else if (modCounter < 41) {
    ctd = "CTD40";
    ctds.map((m: any) => {
      if (m.name === "CTD40") {
        retCtd = m;
      }
    });
    const modulesOver = 40 - modCounter;
    if (modulesOver > 0) {
      retCtd.vrij = modulesOver;
    } else {
      retCtd.vrij = 0;
    }
    expantion = false;
  } else if (modCounter < 56) {
    ctd = "CTD40";
    ctds.map((m: any) => {
      if (m.name === "CTD40") {
        retCtd = m;
      }
    });
    const modulesOver = 55 - modCounter;
    if (modulesOver > 0) {
      retCtd.vrij = modulesOver;
    } else {
      retCtd.vrij = 0;
    }
    expantion = true;
    retmodcounter = 0;
  } else if (modCounter >= 56 && modCounter <= 150) {
    ctd = "CTDmax";
    ctds.map((m: any) => {
      if (m.name === "CTDmax") {
        retCtd = m;
      }
    });
    const modulesOver = 150 - modCounter;
    //debugger
    if (modulesOver > 0) {
      retCtd.vrij = modulesOver;
    } else {
      retCtd.vrij = 0;
    }
    expantion = false;
  } else if (modCounter > 150) {
    ctd = "CTDmax";
    //debugger
    ctds.map((m: any) => {
      if (m.name === "CTDmax") {
        retCtd = m;
      }
    });
    expantion = false;
    //outputs / ctdoutpust
    let x = Math.floor(modCounter / 150);
    const y = modCounter % 150;

    retmodcounter = modCounter - 150;
    // if (y!=0){x=x+1}
    //if (y!=0){x++}
    const modulesOver = 150 * Math.ceil(modCounter / 150) - modCounter;

    if (modulesOver > 0) {
      retCtd.vrij = modulesOver;
    } else {
      retCtd.vrij = 0;
    }
    if (modulesOver >= 56 && modulesOver <= 150) {
      retCtd.vrij = 0;
    }

    if (retmodcounter < 0) {
      retmodcounter = 0;
    }
  }

  const ret = {
    ctd: retCtd,
    expantion: expantion,
    retmodcounter: retmodcounter,
  };

  //console.log(ret)

  return ret;
}

function addCtdsToMods(
  modCounter: number,
  modules: modsArray,
  retCtd: any,
  expantion: boolean
) {
  const exp: any = getExpantion();
  //debugger
  if (modCounter >> 0) {
    modules.mods.push({
      module: retCtd.name,
      aantal: 1,
      prijs: retCtd.prijs,
      vrij: retCtd.vrij,
      modType: "CTD",
      busCons: 0,
      parentModule: 0,
      modId: retCtd.id,
      offCat: retCtd.offCat,
      adresses: 0,
      sa: 0,
      pakket: 0,
      totadres: 0,
    });
  }

  if (expantion === true) {
    modules.mods.push({
      module: exp.name,
      aantal: 1,
      prijs: exp.prijs,
      vrij: 0,
      modType: "EXP",
      busCons: 0,
      parentModule: 0,
      modId: exp.id,
      offCat: exp.offCat,
      adresses: 0,
      sa: 0,
      pakket: 0,
      totadres: 0,
    });
  }

  return modules;
}

function CalcultateCtd(modules: modsArray) {
  //debugger
  const mods = modules.mods;
  const aantalMods = mods.length;

  let modCounter = 0;
  let adresCounter = 0;

  

  mods.map((m: any) => {
    if (m.busCons >> 0) {
      modCounter = modCounter + m.aantal;
      adresCounter = adresCounter + m.totadres;
    }
  });
  //debugger

  //console.log("L: ", modCounter)
  let ctd = "";

  //let expantion = false;

  const ctds = getCtds();

  let ret = addCtds(adresCounter, modCounter, ctds);

  let retCtd: any = ret.ctd;
  let expantion = ret.expantion;
  let retmodcounter = ret.retmodcounter;

  let ctdsNodig = Math.ceil(adresCounter / 380);
  //console.log("Aantal controllers nodig: ", ctdsNodig);


  // ToDo: If CTDMAX - Probably to many outputs on 1 controller
  
  for (let i = 0; i < ctdsNodig; i++) {

  }


// Add first controller

  ret = addCtds(adresCounter, modCounter, ctds);
  retCtd = ret.ctd;
  expantion = ret.expantion;
  retmodcounter = ret.retmodcounter;
  modules = addCtdsToMods(modCounter, modules, retCtd, expantion);

  // If we still have modules over - add a second controller
  if (modCounter > 0) {

    while (retmodcounter != 0) {
      // Todo check if ctd is in array, if so: change counter for offerte
      ret = addCtds(adresCounter, retmodcounter, ctds);
      //debugger
      let multic: boolean = false;
      modules.mods.map((m: any) => {
        if (m.module === ret.ctd.name) {
          m.aantal++;
          retmodcounter = ret.retmodcounter;
          multic = true;
        }
      });

      if (multic === false) {
        // If we have a CTDMAX and need a second controller:
        retCtd = ret.ctd;
        expantion = ret.expantion;
        retmodcounter = ret.retmodcounter;
        modules = addCtdsToMods(modCounter, modules, retCtd, expantion);
      }
        
    }
  }


  return modules;
}

export function GetModById(modId: number) {
  const dbMods: interfaces.ModuleTypes[] = JSON.parse(
    sessionStorage.getItem("DbModules") || "{}"
  );

  let retMod: interfaces.ModuleTypes = {
    id: 0,
    type: "",
    name: "",
    outputs: 0,
    nextModule: 0,
    parentModule: 0,
    inputs: 0,
    busConsumption: 0,
    adresses: 0,
    din: 0,
    icon: "",
    prijs: 0,
    offCat: 0,
    sa: 0,
    pakket: 0,
  };

  if (dbMods) {
    //debugger;
    dbMods.map((mod: interfaces.ModuleTypes) => {
      if (mod.id === modId) {
        retMod = mod;
      }
    });
  }

  return retMod;
}

function AddParentMod(parent: interfaces.ModuleTypes) {}

function checkMod(
  modarray: modsArray,
  mod: interfaces.ModuleTypes,
  aantal: number
) {
  //console.log("Aantal modules: ", aantal )
  //debugger

  // Todo Check if modarray contains
  if (!modarray.mods.some((e) => e.module === mod.name)) {
    //}

    //if (modarray.mods.length === 0) {
    let vrij = mod.outputs;
    let aantalMods = 1;
    let totadres = 0;
    //debugger
    if (aantal < mod.outputs) {
      vrij = mod.outputs - aantal;
      aantalMods = 1;
      totadres = aantal * mod.adresses;
    } else {
      //debugger
      const totalMods = Math.ceil(aantal / mod.outputs);
      const overschot = totalMods * mod.outputs - aantal;
      aantalMods = totalMods;
      vrij = overschot;
      totadres = totalMods * mod.outputs * mod.adresses;
    }

    let offerteItem: mods1 = {
      module: mod.name,
      aantal: aantalMods,
      prijs: mod.prijs,
      vrij: vrij,
      modType: mod.type,
      busCons: mod.busConsumption,
      parentModule: mod.parentModule,
      modId: mod.id,
      offCat: mod.offCat,
      adresses: mod.adresses,
      sa: mod.sa,
      pakket: mod.pakket,
      totadres: totadres,
    };
    modarray.mods.push(offerteItem);
  } else {
    modarray.mods.map((moda: mods1) => {
      //debugger
      if (aantal < moda.vrij) {
        moda.vrij = moda.vrij - aantal;
        //moda.aantal = 1
      } else {
        const totalMods = moda.aantal + Math.ceil(aantal / mod.outputs);
        const overschot =
          totalMods * mod.outputs -
          (aantal + moda.aantal * mod.outputs - moda.vrij);
        moda.aantal = totalMods;
        moda.vrij = overschot;
      }

      //console.log("ex: ", moda)
    });
  }

  return modarray;
}

function CalculateParentMods(modules: modsArray) {
  const mods = modules.mods;
  //debugger
  let modsOnParent = 0;

  let parent: interfaces.ModuleTypes = {
    id: 0,
    type: "",
    name: "",
    outputs: 0,
    nextModule: 0,
    parentModule: 0,
    inputs: 0,
    busConsumption: 0,
    adresses: 0,
    din: 0,
    icon: "",
    prijs: 0,
    offCat: 0,
    sa: 0,
    pakket: 0,
  };

  let retMods: modsArray = { mods: [] };

  //let retMods: [mod:{}] = [{}];
  retMods.mods.pop();

  mods.map((m: any) => {
    if (m.parentModule >> 0) {
      parent = GetModById(m.parentModule);
      retMods = checkMod(retMods, parent, m.aantal);
    }
  });

  if (retMods.mods.length > 0) {
    // debugger
    retMods.mods.map((moda: mods1) => {
      mods.push(moda);
    });
  }

  //debugger
  return modules;
}

export function CalculateModulesTotal(
  moduleMap: Map<string, object>,
  full: boolean,
  indienst: boolean,
  totPrijs: number
) {
  type mods = {
    [key: string]: any;
    value: number;
  };

  type m = {
    used: number;
    vrij: number;
    modType: string;
    usedAddresses: number;
  };

  let offerteItems: mods1 = {
    module: "123",
    aantal: 5,
    prijs: 0,
    vrij: 0,
    modType: "",
    busCons: -1,
    parentModule: 0,
    modId: 0,
    offCat: 0,
    adresses: 0,
    sa: 0,
    pakket: 0,
    totadres: 0,
  };
  let modules: modsArray = { mods: [offerteItems] };

  modules.mods.pop();

  // Todo Sort modulesmap -> set ROL before REL and change to rel
  //moduleMap.forEach((_value, key) => {

  //   });

  moduleMap.forEach((_value, key) => {
    const k: mods = moduleMap.get(key) as mods;

    k.forEach((value: m, key: string) => {
      //if (key==="ROL") {key="REL"}
      //debugger
      let modId: any = findModId(key); //extended mods
      let dbMod: any = getModuleParameters(key);

      if (full == false) {
        if (dbMod.sa == 1) {
          modules.mods.push({
            module: key,
            aantal: value.used,
            prijs: modId.prijs,
            vrij: value.vrij,
            modType: value.modType,
            busCons: dbMod.busConsumption,
            parentModule: dbMod.parentModule,
            modId: modId.module_id,
            offCat: dbMod.offCat,
            adresses: dbMod.adresses,
            sa: dbMod.sa,
            pakket: dbMod.pakket,
            totadres: value.usedAddresses,
          });
        }
      } else {
        modules.mods.push({
          module: key,
          aantal: value.used,
          prijs: modId.prijs,
          vrij: value.vrij,
          modType: value.modType,
          busCons: dbMod.busConsumption,
          parentModule: dbMod.parentModule,
          modId: modId.module_id,
          offCat: dbMod.offCat,
          adresses: dbMod.adresses,
          sa: dbMod.sa,
          pakket: dbMod.pakket,
          totadres: value.usedAddresses,
        });
      }
    });
  });

  /*
  let aantInd = +(totPrijs / 100).toFixed(2);

  let indienstItem: mods1 = {
    module: "INDIENST",
    aantal: aantInd,
    prijs: 88,
    vrij: 0,
    modType: "INDIENTS",
    busCons: 0,
    parentModule: 0,
    modId: 180,
    offCat: 3,
  }

*/

  const ewm = CalculateParentMods(modules);

  //const full = sessionStorage.getItem("Full");

  if (full === true) {
    const modsCtd = CalcultateCtd(ewm);
    // if (indienst === true) {
    //   modsCtd.mods.push(indienstItem)
    //  }
    return modsCtd;
  } else {
    // if (indienst === true) {
    //    ewm.mods.push(indienstItem)
    //  }
    return ewm;
  }

  //debugger
  //modules.mods.push(ewm)
  const modsCtd = CalcultateCtd(ewm);

  return modsCtd;
}

export function calculateSA(
  me: any,
  onlySa: boolean,
  rel04_08: boolean,
  update: boolean
) {
  const modulesMap = getModules(me.props.arr, onlySa, rel04_08);

  //console.log("MODMAP: ", modulesMap)
  // 0: key: REL // value:  key: REL02SA/230 value: used: x, overs: x
  let modules: modsArray2 = {
    mods: [
      {
        module: "123",
        aantal: 5,
        prijs: 0,
        vrij: 0,
        modType: "",
        modId: 0,
        sa: 0,
        pakket: 0,
      },
    ],
  };

  modules = CalculateModulesTotal(
    modulesMap,
    false,
    me.props.indienst,
    me.OffTotaal
  );
/*
  let aantInd = +(me.OffTotaal / 100).toFixed(2);
  if (update === true) {
    me.props.onDataChange(modules);
    //console.log(modules);
  }
    */
  return modules;
}

export function calculate(
  me: any,
  onlySa: boolean,
  rel04_08: boolean,
  update: boolean
) {
  //console.log("OVERZICHT TSX - CALC:", this.props.arr )
  //debugger
  const modulesMap = getModules(me.props.arr, onlySa, rel04_08);

  //console.log("MODMAP: ", modulesMap)
  // 0: key: REL // value:  key: REL02SA/230 value: used: x, overs: x
  let modules: modsArray2 = {
    mods: [
      {
        module: "123",
        aantal: 5,
        prijs: 0,
        vrij: 0,
        modType: "",
        modId: 0,
        sa: 0,
        pakket: 0,
      },
    ],
  };

  modules = CalculateModulesTotal(
    modulesMap,
    me.props.full,
    me.props.indienst,
    me.OffTotaal
  );


  return modules;
}
