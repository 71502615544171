import axios from "../login/api/axios"

export interface projectFromDatabase  {
    success: [
      {
        id: number;
        userid: string;
        name: string;
      }
    ]
  }

 // const accessToken = localStorage.getItem("auth")
  
const user = sessionStorage.getItem('user');

const SAVEPROJECT_URL = '/saveproject';
const GETPROJECTS_URL = '/getprojects';
const OPENPROJECT_URL = '/openproject';
    
export const SaveProject = (data:any, name:string, klant:number) => {
    
    const accessToken = sessionStorage.getItem('accessToken')
    const handleCreateProject = async () => {
        try {
            const response = await axios.post(
                SAVEPROJECT_URL,
              JSON.stringify({ user, data, name, klant }),
              {
                headers: { 
                    'Authorization' : 'Bearer ' + accessToken,
                    "Content-Type": "application/json",
                  },
                  withCredentials: true,
              }
            );
            return response;
        } catch (err) {
            console.log(err)
        }
    };
    return handleCreateProject()
}

export function save2(data:any, name:string, klant:number):any {
    const projects = new Promise<projectFromDatabase>((resolve, reject) => {
        const accessToken = sessionStorage.getItem('accessToken')
        const user = sessionStorage.getItem('user');
        axios.post(
                SAVEPROJECT_URL,
              JSON.stringify({ user, data, name, klant }),
              {
                headers: { 
                    'Authorization' : 'Bearer ' + accessToken,
                    "Content-Type": "application/json",
                  },
                  withCredentials: true,
              }
            )
            .then(res => {
                if (res) {
                    //console.log(res)
                    resolve (res.data)
                } else {
                    reject ("DB No modules found")
                }
            })
            .catch(error => {
                console.log ("Error: DB No modules found: " + error)
            })
    });
    return projects
}

export function UpdateProject2(data:any, name:string, klant:number, id:number):any {
    const projects = new Promise<projectFromDatabase>((resolve, reject) => {
        const accessToken = sessionStorage.getItem('accessToken')
        const user = sessionStorage.getItem('user');
        axios.post(
                SAVEPROJECT_URL,
              JSON.stringify({ user, data, name, klant, id }),
              {
                headers: { 
                    'Authorization' : 'Bearer ' + accessToken,
                    "Content-Type": "application/json",
                  },
                  withCredentials: true,
              }
            )
            .then(res => {
                if (res) {
                    //console.log(res)
                    resolve (res.data)
                } else {
                    reject ("DB No modules found")
                }
            })
            .catch(error => {
                console.log ("Error: DB No modules found: " + error)
            })
    });
    return projects
}

export const UpdateProject = (data:any, name:string, id:number) => {
    const accessToken = sessionStorage.getItem('accessToken')
    const handleCreateProject = async () => {
        try {
            const response = await axios.post(
                SAVEPROJECT_URL,
              JSON.stringify({ user, data, name, id }),
              {
                headers: { 
                    'Authorization' : 'Bearer ' + accessToken,
                    "Content-Type": "application/json",
                  },
                  withCredentials: true,
              }
            );
        } catch (err) {
            console.log(err)
        }
    };
    handleCreateProject()
}


export function GetProjects():any {
    
    const projects = new Promise<projectFromDatabase>((resolve, reject) => {
        const accessToken = sessionStorage.getItem('accessToken')
        const userId = sessionStorage.getItem('userid');
        //console.log("gettin " + accessToken)
        axios.get(
            GETPROJECTS_URL,
            {
            headers: { 
                'Authorization' : 'Bearer ' + accessToken,
                "Content-Type": "application/json",
            },
            withCredentials: true,
            params: {user:userId}
            },
        )
        .then(res => {
            if (res) {
                //console.log(res)
                resolve (res.data)
            } else {
                reject ("DB No modules found")
            }
        })
        .catch(error => {
            console.log ("Error: DB No modules found: " + error)
        })
    });
    return projects
} 



/*
export const GetProjects = new Promise<projectFromDatabase>((resolve, reject) => {
    console.log("gettin")
    axios.get(
        GETPROJECTS_URL,
        {
        headers: { 
            'Authorization' : 'Bearer ' + accessToken,
            "Content-Type": "application/json",
        },
        withCredentials: true,
        params: {user:'10'}
        },
    )
    .then(res => {
        if (res) {
            console.log(res)
            resolve (res.data)
        } else {
            reject ("DB No modules found")
        }
    })
    .catch(error => {
        console.log ("Error: DB No modules found: " + error)
    })
}); 
*/
export function OpenProject(id:number):any {
    const accessToken = sessionStorage.getItem('accessToken')
    const project = new Promise<projectFromDatabase> ((resolve, reject) => {
        //console.log("opening project with id: ", id)
        axios.get(
            OPENPROJECT_URL,
            {
            headers: { 
                'Authorization' : 'Bearer ' + accessToken,
                "Content-Type": "application/json",
            },
            withCredentials: true,
            params: {projid:id}
            },
        )
        .then(res => {
        if (res) {
            //console.log(res)
            resolve (res.data)
        } else {
            reject ("DB No modules found")
        }
        })
        .catch(error => {
            console.log ("Error: DB No modules found: " + error)
        })
    }); 
    return project
}
 
