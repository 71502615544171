import axios from "../login/api/axios"



const USERINFO_URL = '/getuserinfo';
const CUSTOMERS_URL = '/getcustomers';
const GETCUSTOMER_URL = '/getcustomerbyid';
const NEWCUSTOMER_URL = '/newcustomer';
const UPDATECUSTOMER_URL = '/updatecustomer'
const UPDATEUSERINFO_URL = '/updateuserinfo'

export interface userIfo  {
    success: [
      {
        id: number;
        user_id: number;
        naam: string;
        voornaam: string;
        straat: string;
        huisnr: string;
        postcode: string;
        gemeente: string;
        land: string;
        telefoon: string;
        btwnr: string;
      }
    ]
  }

export function GetUserInfo():any {
    const accessToken = sessionStorage.getItem('accessToken')
    const userId = sessionStorage.getItem('userid');
    const project = new Promise<userIfo> ((resolve, reject) => {
        //console.log("opening project with id: ", id)
        axios.get(
            USERINFO_URL,
            {
            headers: { 
                'Authorization' : 'Bearer ' + accessToken,
                "Content-Type": "application/json",
            },
            withCredentials: true,
            params: {user:userId}
            },
        )
        .then(res => {
        if (res) {
            //console.log(res)
            resolve (res.data)
        } else {
            reject ("DB No user found")
        }
        })
        .catch(error => {
            console.log ("Error: DB No modules found: " + error)
        })
    }); 
    return project
}

export function GetCustomers():any {
    
    const custommers = new Promise<userIfo>((resolve, reject) => {
        const accessToken = sessionStorage.getItem('accessToken')
        const userId = sessionStorage.getItem('userid');
        //console.log("gettin")
        axios.get(
            CUSTOMERS_URL,
            {
            headers: { 
                'Authorization' : 'Bearer ' + accessToken,
                "Content-Type": "application/json",
            },
            withCredentials: true,
            params: {user:userId}
            },
        )
        .then(res => {
            if (res) {
                //console.log(res)
                resolve (res.data)
            } else {
                reject ("DB No modules found")
            }
        })
        .catch(error => {
            console.log ("Error: DB No modules found: " + error)
        })
    });
    return custommers
} 

export function getCustomerById(customerId:number):any {
    const accessToken = sessionStorage.getItem('accessToken')
   // const userId = sessionStorage.getItem('userid');

    const project = new Promise<userIfo> ((resolve, reject) => {
        //console.log("opening project with id: ", id)
        axios.get(
            GETCUSTOMER_URL,
            {
            headers: { 
                'Authorization' : 'Bearer ' + accessToken,
                "Content-Type": "application/json",
            },
            withCredentials: true,
            params: {id:customerId}
            },
        )
        .then(res => {
        if (res) {
            //console.log(res)
            resolve (res.data)
        } else {
            reject ("DB Customer not found ")
        }
        })
        .catch(error => {
            console.log ("Error: DB No modules found: " + error)
        })
    }); 
    return project
}

export function HandleNewCustomer(naam:string, voornaam:string, firma:string, straat:string, huisnr:string, postcode:string, gemeente:string, land:string, telefoon:string, btwnr:string)  {
    const accessToken = sessionStorage.getItem('accessToken')
    const userId = sessionStorage.getItem('userid')
    const handleNewUser = async () => {
        try {
            const response = await axios.post(
                NEWCUSTOMER_URL,
              JSON.stringify({ userId, naam, voornaam, firma, straat, huisnr, postcode, gemeente, land, telefoon, btwnr }),
              {
                headers: { 
                    'Authorization' : 'Bearer ' + accessToken,
                    "Content-Type": "application/json",
                  },
                  withCredentials: true,
              }
            )
            return response
        } catch (err) {
            console.log(err)
        }
    };
    return handleNewUser();
}

export function UpdateCustomer(id:any,naam:string, voornaam:string, firma:string, straat:string, huisnr:string, postcode:string, gemeente:string, land:string, telefoon:string, btwnr:string)  {
    const accessToken = sessionStorage.getItem('accessToken')
    const userId = sessionStorage.getItem('userid')
    const handleNewUser = async () => {
        try {
            const response = await axios.post(
                UPDATECUSTOMER_URL,
              JSON.stringify({ id, userId, naam, voornaam, firma, straat, huisnr, postcode, gemeente, land, telefoon, btwnr }),
              {
                headers: { 
                    'Authorization' : 'Bearer ' + accessToken,
                    "Content-Type": "application/json",
                  },
                  withCredentials: true,
              }
            )
            return response
        } catch (err) {
            console.log(err)
        }
    };
    return handleNewUser();
}

export function UpdateUserInfo(id:any,naam:string, voornaam:string, firma:string, straat:string, huisnr:string, postcode:string, gemeente:string, land:string, telefoon:string, btwnr:string)  {
    const accessToken = sessionStorage.getItem('accessToken')
    const userId = sessionStorage.getItem('userid')
    const handleupdateUserInfo = async () => {
        try {
            const response = await axios.post(
                UPDATEUSERINFO_URL,
              JSON.stringify({ id, userId, naam, voornaam, firma, straat, huisnr, postcode, gemeente, land, telefoon, btwnr }),
              {
                headers: { 
                    'Authorization' : 'Bearer ' + accessToken,
                    "Content-Type": "application/json",
                  },
                  withCredentials: true,
              }
            )
            return response
        } catch (err) {
            console.log(err)
        }
    };
    return handleupdateUserInfo();
}