import React, { Component } from "react";
import AdminProductTable from "./products";

interface MyState {
  }
  
  interface MyProps {
  }
  
  class AdminPanel extends Component<MyProps, MyState> {
    constructor(props: any) {
      super(props);

    }

    render() {
      const role = sessionStorage.getItem("role");
      console.log(role)
      if (role === "Admin") {
        return (
            <>
            ADMIN
                     <AdminProductTable />  

            </>
        )
      } else {
        <>Not authorized</>
      }
    }
}

export default AdminPanel;