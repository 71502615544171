import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

interface MyState {
  }

  interface MyProps {
    visible: boolean;
    message: string;
    onDataChange: (t:any) => any;
  }

class Errors extends Component<MyProps, MyState>{
    constructor(props:any) {
      super(props);
    }

    vstate = {settings:"hidden"}

    handleClose = () => {

        this.props.onDataChange(false)
    }

    render() {
        return (
            
            <Modal show={this.props.visible === true ? true : false} >
                <Modal.Header closeButton onClick={this.handleClose}>
                <Modal.Title><h1> Fout</h1></Modal.Title>
                </Modal.Header>
                <Modal.Body >
                
                {this.props.message}


                </Modal.Body>
                <Modal.Footer>
                <Button variant="danger" onClick={this.handleClose}>
                    OK
                </Button>
                </Modal.Footer>
            </Modal>
        )
      }
}

export default Errors;