import { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { CSSProperties } from "react";
import DotLoader from "react-spinners/BarLoader";
import * as functions from "./opbouwFunctions";

import OutputModulesTree from "./Modules/outputmodules";
import BuildingTree from "./Woning/buildingtree";
import Overzicht from "./Overzicht/overzicht";

import { Icon } from "@mui/material";
import { WoningIcon } from "../images/icons/woning.jsx";


import "./opbouw.css";
import { ExpandCircleDown } from "@mui/icons-material";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

interface MyProps {
  tempWoning: any;
  loadedWoning: any;
  uitvoering: string;
  onDataChange: (t: any) => any;
  onModulesUpdated: (t: any, sa: any) => any;
  onInDienstChanged: (t: any) => any;
  full: boolean;
  paketten: boolean;
  rel04_08: boolean;
  indienst: boolean;
  // calculating: boolean;
  // startCalc: () => any;
  // stopCalc: () => any;
}
type MyState = {
};

class Opbouw extends Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);

    this.HandleModulesItemClicked = this.HandleModulesItemClicked.bind(this);
    this.HandleWoningItemClicked = this.HandleWoningItemClicked.bind(this);
    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.HandleOfferte = this.HandleOfferte.bind(this);
    this.functionFromBuildingTreeChangeItemName =
      this.functionFromBuildingTreeChangeItemName.bind(this);
    this.functionFromBuildingTreeRemoveItem =
      this.functionFromBuildingTreeRemoveItem.bind(this);
    this.functionFromBuildingTreeChangeIcon =
      this.functionFromBuildingTreeChangeIcon.bind(this);
      this.handleInDienstChanged = this.handleInDienstChanged.bind(this)
  }

  woningIcon: any = (
    <Icon key="woning">
      <WoningIcon />
    </Icon>
  );

  rootTreeItem = {
    id: "1",
    name: "Woning",
    children: [],
    icon: this.woningIcon,
    expanded: true,
  };

  woningDb: functions.dbItem[] = [];

  woningTree = this.rootTreeItem;

  woningSlectedNodeId = { id: "1", type: "woning", label: "Woning" };

  ctd = "define";

  calculating = false;

  // This function allows to update the screen with loaded values after a page refresh
  componentDidMount(): void {
    const wo = sessionStorage.getItem("loadedWoning");
    const wo2 = JSON.parse(wo || "{}");
    
    try {
      
      wo2.map((tw: any) => {
        this.setState((this.woningTree = this.ChangewoningTree(tw, tw.parent)));
        this.props.onDataChange(this.woningDb);
        sessionStorage.setItem("woningTree", JSON.stringify(this.woningTree));
      });
    } catch (error) {}
     
  }

  // This function allows to open a project
  componentDidUpdate(
    prevProps: Readonly<MyProps>,
    prevState: Readonly<MyState>,
    snapshot?: any
  ): void {
    // update woningtree after 'bestand - open' / 'bestand - nieuw' received from NAV
    //debugger
    if (this.props.loadedWoning !== prevProps.loadedWoning) {
      let newFile = false;
      try {
        const childCounter = this.props.loadedWoning[0].children.length;
        if (childCounter === 0) {
          newFile = true;
        }
      } catch (error) {}
      // this.woningDb = file to save
      this.woningDb = [];
      if (newFile === true) {
        //console.log("NEW");
        this.woningTree.children = this.props.loadedWoning[0].children;
        this.setState((this.woningTree = this.rootTreeItem));
        this.props.onDataChange(this.woningDb);
      } else {
        //console.log("OPEN");
        this.woningTree.children = [];
        //console.log("LW: ", this.props.loadedWoning);
        this.setState((this.woningTree = this.rootTreeItem));

        this.props.loadedWoning.map((tw: any) => {
          //console.log("TW: ", tw);
          this.setState(
            (this.woningTree = this.ChangewoningTree(tw, tw.parent))
          );

          //this.woningDb = [];
          this.props.onDataChange(this.woningDb);

          sessionStorage.setItem("woningTree", JSON.stringify(this.woningTree));
          sessionStorage.setItem(
            "loadedWoning",
            JSON.stringify(this.props.loadedWoning)
          );
        });
      }
    }
  }

  updateMainWoning() {
    // Send woningDb to Main (handleWoningChanged) -> sets project
    
    this.props.onDataChange(this.woningDb);
    // Save woningDb to sessionstorage (for page refresh)
    sessionStorage.setItem("loadedWoning", JSON.stringify(this.woningDb));
  }

  functionFromBuildingTreeRemoveItem(itemId: string) {
    this.setState((this.woningTree = functions.RemoveTreeItem(itemId, this.woningTree, this.woningDb)));
    this.updateMainWoning();
  }

  functionFromBuildingTreeChangeItemName(itemId: string, newName: string) {
    this.setState((this.woningTree = functions.ChangeTreeItemName(itemId, newName, this.woningTree, this.woningDb)));
    this.updateMainWoning();
  }

  functionFromBuildingTreeChangeIcon(itemId: string, icon: string) {
    this.setState((this.woningTree = functions.ChangeTreeItemIcon(itemId, icon, this.woningTree, this.woningDb)));
    this.updateMainWoning();
  }

  ChangewoningTree = (moduleItem: functions.dbItem, parentId: string) => {
    //debugger
    this.woningDb.push(moduleItem);
    return functions.AddItemToWoningTree(moduleItem, parentId, this.woningTree);
  };

  ClearWoningTree = () => {
    let woningObject = this.rootTreeItem;

    return woningObject;
  };

  //Item from outputmodules - New item added
  HandleModulesItemClicked(moduleItem: functions.dbItem, parentId: string) {
    //debugger
    this.setState(
      (this.woningTree = this.ChangewoningTree(moduleItem, parentId))
    );
    this.updateMainWoning();
  }


  HandleWoningItemClicked(type: string, nodeId: string, label: string) {
    this.setState(
      (this.woningSlectedNodeId = functions.ChangeSelectedFloor(this.woningSlectedNodeId, nodeId, type, label))
    );
  }

  HandleOfferte(modules: any, noSaMods:any) {
    this.props.onModulesUpdated(modules, noSaMods); // => Navbar handleModules
    sessionStorage.setItem("offerteModules", JSON.stringify(modules));
  }

  handleInDienstChanged(ind: boolean) {
    //debugger
    this.props.onInDienstChanged(ind)
  }
/*
  ShowLoading = () => {
    return (
      <div className="sweet-loading">
        <DotLoader
          color={"#F3BA0D"}
          loading={true}
          cssOverride={override}
          height={10}
          width={300}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  };

*/
  //{this.props.calculating === true ? this.ShowLoading() : ""}
  render() {
    return (
      <Container>
        <br></br>
        <Row>
          <Col xs={3}>
            {" "}
            <BuildingTree
              arr={this.woningTree}
              onWoningItemClick={this.HandleWoningItemClicked}
              onNameChange={this.functionFromBuildingTreeChangeItemName}
              onIconChange={this.functionFromBuildingTreeChangeIcon}
              onRemoveItem={this.functionFromBuildingTreeRemoveItem}
            />{" "}
          </Col>
          <Col xs={4}>
            {" "}
            <OutputModulesTree
              woningSlectedId={this.woningSlectedNodeId}
              onDataChange={this.HandleModulesItemClicked}
              uitvoering={this.props.uitvoering}
            />{" "}
          </Col>
          <Col xs={5}>
            {" "}
            <Overzicht
              arr={this.woningTree}
              db={this.woningDb}
              uitvoering={this.props.uitvoering}
              ctd={this.ctd}
              onDataChange={this.HandleOfferte}
              full={this.props.full}
              paketten={this.props.paketten}
              indienst={this.props.indienst}
              rel04_08={this.props.rel04_08}
              onInDienstChanged={this.handleInDienstChanged}
              // stopCalc={this.props.stopCalc}
            />{" "}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Opbouw;
