import * as myTreeIcons from "../../images/icons/treeicons";
import * as interfaces from "./sharedInterfaces";
import * as functions from "./sharedFunctions";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import Box from "@mui/material/Box";
import { TreeView } from "@mui/x-tree-view/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Children } from "react";

import { DimmerIcon } from "../../images/icons/dimmer";

interface RenderKastTree {
  id: string;
  name: string;
  icon: string;
  children?: readonly RenderKastTree[];
}

interface moduleOutputs {
  id: string;
  name: string;
}

interface kastModuleOutputs {
  moduleName: string;
  ctd: number;
  outputs: Array<moduleOutputs>;
}

interface kastModsArray {
  ctd: string;
  ctdMods: Array<kastModuleOutputs>;
  decentraalMods: Array<kastModuleOutputs>;
}

interface ctds {
  ctd: interfaces.treeItem;
  modc: number;
  din: interfaces.treeItem;
  dec: interfaces.treeItem;
}

interface dbItem {
  nodeid: string;
  label: string;
  type: string;
  parent: string;
  typeIcon: string;
  moduletype: string;
  outputOptions: JSON;
}

const data: RenderKastTree = {
  id: "root",
  name: "Kast",
  icon: myTreeIcons.Lightning(),
  children: [
    {
      id: "1",
      name: "DIM04SA",
      icon: myTreeIcons.DimmerTreeIcon(),
      children: [
        {
          id: "5",
          name: "Lamp 1",
          icon: myTreeIcons.DimmerTreeIcon(),
        },
      ],
    },
    {
      id: "3",
      name: "REL04SA",
      icon: myTreeIcons.RelaisTreeItem(),
      children: [
        {
          id: "4",
          name: "Lamp 2",
          icon: myTreeIcons.RelaisTreeItem(),
        },
      ],
    },
  ],
};

const decentraal: RenderKastTree = {
  id: "root",
  name: "Decentraal",
  icon: myTreeIcons.Lightning(),
  children: [
    {
      id: "1",
      name: "REL02",
      icon: myTreeIcons.RelaisTreeItem(),
      children: [
        {
          id: "5",
          name: "Lamp 1",
          icon: myTreeIcons.RelaisTreeItem(),
        },
      ],
    },
    {
      id: "3",
      name: "REL02",
      icon: myTreeIcons.RelaisTreeItem(),
      children: [
        {
          id: "4",
          name: "Lamp 2",
          icon: myTreeIcons.RelaisTreeItem(),
        },
      ],
    },
  ],
};

function getOutputsForModTypeDB(type: string, db: dbItem[]) {
  //console.log("type: ", type)
  //let retitems: moduleOutputs[] = [];
  let retitems: interfaces.treeItem[] = [];
  //debugger

  function createChildren(item: any, icon: any, cnt: number) {
    let childItem: interfaces.treeItem = {
      id: item.nodeid + cnt,
      name: item.label + cnt,
      icon: icon,
      expanded: true,
      children: [],
    };
    return childItem;
  }

  function loop() {
    db.map((item: any) => {
      if (item.type === type) {
        let icon = myTreeIcons.getTreeIconByDbType(type);

        if (icon === null) {
          icon = myTreeIcons.getTreeIcon2(item.typeIcon, type);
        }

        let treeItem: interfaces.treeItem = {
          id: item.nodeid,
          name: item.label,
          icon: icon,
          expanded: true,
          children: [],
        };

        if (type === "DMX") {
          let options = item.outputOptions;
          let counter = Number(options.DmxType);
          console.log(counter);
          for (let x = 0; x < counter; x++) {
            //console.log(x)
            //debugger
            //console.log(counter)
            let c: interfaces.treeItem = createChildren(item, icon, x);
            treeItem.children?.push(c);
          }
        }

        // console.log(item.children)
        //let retItem = { id: item.id, name: item.name, icon: item.icon };
        retitems.push(treeItem);
        //retitems.push(retItem);
        //console.log(retitems);
      }
    });
  }

  if (type === "REL") {
    type = "ROL";
    loop();
    type = "hand";
    loop();
    type = "fan";
    loop();
    type = "stopc";
    loop();
    type = "REL";
  }

  loop();
//debugger
  return retitems;
}

function getOutputsForModType(type: string, arr: myTreeIcons.kamerItem) {
  //console.log("type: ", type)
  //let retitems: moduleOutputs[] = [];
  let retitems: interfaces.treeItem[] = [];
  //debugger
  //if (type==="ROL"){type = "REL"}
  //console.log("c: ", arr)
  const x = (modItems: myTreeIcons.kamerItem) => {
    modItems.children?.map((item: any) => {
      // debugger
      //if (item.icon.key === type) {
      if (item.icon.key.includes(type)) {
        let treeItem: interfaces.treeItem = {
          id: item.id,
          name: item.name,
          icon: item.icon,
          expanded: true,
          children: [],
        };

        // console.log(item.children)
        let retItem = { id: item.id, name: item.name, icon: item.icon };
        retitems.push(treeItem);
        //retitems.push(retItem);
      }
    });
    let z = Array.isArray(modItems.children)
      ? modItems.children.map((item: any) => {
          x(item);
        })
      : "";
  };

  //debugger
  if (type === "REL") {
    type = "ROL";
    x(arr);
    type = "hand";
    x(arr);
    type = "fan";
    x(arr);
    type = "stopc";
    x(arr);
    type = "REL";
  }

  x(arr);

  return retitems;
}

export function CalculateKast(
  modArray: interfaces.modsArray,
  //arr: myTreeIcons.kamerItem,
  onlySa: boolean,
  db: any
) {
  /*
  let kastModuleOutputs: kastModuleOutputs = {
    moduleName: "REL1",
    ctd: 0,
    outputs: [{id:"1", name: "name"}],
  };
*/

  let kastModuleOutputs: interfaces.treeItem = {
    id: "Kast",
    name: "Kast",
    icon: null,
    expanded: true,
    children: [],
  };

  let ctdItem: interfaces.treeItem = {
    id: "CTD",
    name: "CTD",
    icon: null,
    expanded: true,
    children: [],
  };

  let decentraalModuleOutputs: interfaces.treeItem = {
    id: "Decentraal",
    name: "Decentraal",
    icon: null,
    expanded: true,
    children: [],
  };

  let dinModuleOutputs: interfaces.treeItem = {
    id: "DIN",
    name: "DIN",
    icon: null,
    expanded: true,
    children: [],
  };

  let kastMods = [];
  //let decMods = [];
  //let dinMods = [];

  let ctdDin: ctds[] = [];
  
  kastMods.push(kastModuleOutputs);
  //decMods.push(kastModuleOutputs);

  const mods = modArray.mods;
  //console.log("mods: ", modArray.mods)
  // First loop - get mod types from Module list (offerte items)
  let TypeArray: string[] = [];
  mods.map((m: any) => {
    const modType = m.modType;
    if (!TypeArray.includes(modType)) {
      TypeArray.push(modType);
    }
  });

  //console.log("ta:", TypeArray)
  //TypeArray: ["REL", "DIM", ...]
  //console.log("DB: ", db);

  // Create array of indexes by type
  const getAllIndexes = (arr: any, type: any) => {
    return arr
      .map((elm: any, idx: any) => (elm.modType === type ? idx : ""))
      .filter(String);
  };

  // First get controller
  let controller = "";
  let controllers = [];
  let ctdCounter = 1;
  let ctdId = 0;
  function getCtdModCntr(ctd: string) {
    let cntr = 0;
    ctdss.map((a: any) => {
      if (a.name === ctd) {
        cntr = a.outputs;
      }
    });
    return cntr;
  }

  // Get CTDs from DBModules
  let ctdss = functions.getCtds();

  //debugger
  // Add controllers to tree
  let exp = false;

  TypeArray.map((type) => {
    if (type.includes("EXP")) {
      exp = true;
    }
  });
//debugger
  // Get controller
  TypeArray.map((type) => {
    if (type.includes("CTD")) {
      let ctd = getAllIndexes(mods, type);
      //console.log("CTD: ", mods[ctd]);
      //debugger

      for (let c in ctd) {
        controller = mods[ctd[c]].module;
        let aantalCtds = mods[ctd[c]].aantal;

        for (let d = 0; d < aantalCtds; d++) {
          let ctdItem: interfaces.treeItem = {
            id: mods[ctd[c]].module + "-" + ctdCounter.toString(),
            name: mods[ctd[c]].module + "-" + ctdCounter.toString(),
            icon: myTreeIcons.getTreeIcon2("ctd", ""),
            expanded: true,
            children: [],
          };

          let DinItem : interfaces.treeItem = {
            id: "Din" + mods[ctd[c]].module + "-" + ctdCounter.toString(),
            name: "Dinrail",
            icon: myTreeIcons.getTreeIcon2("ctd", ""),
            expanded: true,
            children: [],
          };

          let DecItem : interfaces.treeItem = {
            id: "Dec-" + mods[ctd[c]].module + "-" + ctdCounter.toString(),
            name: "Decentraal",
            icon: myTreeIcons.getTreeIcon2("ctd", ""),
            expanded: true,
            children: [],
          };

          ctdCounter++;

          let maxMods = getCtdModCntr(mods[ctd[c]].module);

          if (exp === true) {
            maxMods = maxMods + 15;
          }

          let ctdr: ctds = { ctd: ctdItem, modc: maxMods, din: DinItem, dec: DecItem };

          ctdDin.push(ctdr);
        }

        ctdId = 0;
      }
    }
  });
  //debugger
  let decCounter = 0;

  // Main loop
  // Todo:  multicontrollers:
  // Loop controllers - add modules
  // ToDo: hoeveel modules op controller?
  // Volgorde van controllers
  // Ook rekening houden met module manueel op andere controller te plaatsen
  let ctdmodc = 0;
  TypeArray.map((type) => {
    if (type != "") {
      // Get modules from mods by type (REL, DIM, ...)
      // mods: {mods:[{module:"123", aantal:5, prijs:0, vrij: 0, modType: ""}]}
      let modIndexes = [];
      //debugger
      if (type === "ROL") {
        type = "REL";
        modIndexes = getAllIndexes(mods, type);
      }
      modIndexes = getAllIndexes(mods, type);

      // 1e loop bv REL s
      // REL [0, 1]
      // REL08 en REL02
      // mods[0] = REL08
      // mods[1] = REL02

      // Get all outputs for this mod type
      //let outputs = getOutputsForModType(type, arr);
      let outputs = getOutputsForModTypeDB(type, db);

      //console.log("outp: ", outputs);
      //[{icon, id, name}]
      let f = 0;

      let modsonctd = 0;
      if (ctdDin.length != 0) {
        try {
          if (ctdDin[ctdId].modc) {
            // debugger
            modsonctd = ctdDin[ctdId].modc;
          }
        } catch (error) {
          //debugger
        }
      }

      modIndexes.map((modType: any) => {
        //console.log("modt: ", mods[modType]);
        // Request mod from databas to find out max outputs on module
        let mod = functions.getModule(mods[modType].modType, onlySa);
        let outpOnMod = 0;
        let nextModule = null;
        let DinMod = 0;

        mod.retModules.map((module) => {
          if (module.name === mods[modType].module) {
            outpOnMod = module.outputs;
            nextModule = module.nextModule;
          }
          if (module.din >> 0) {
            DinMod = module.din;
          }
        });

        //Get outputs for this mod from output array
        //let modOutputs = outputs.slice(0, outpOnMod);

        for (let i = 0; i < mods[modType].aantal; i++) {
          if (ctdmodc === modsonctd) {
            //debugger
            ctdId++;
            ctdmodc = 0;
          }

          let modOutputsx = outputs.slice(f, f + outpOnMod);

          if (DinMod > 0) {
            //debugger
            if (mods[modType].module.includes("CTD")) {
            } else if (mods[modType].module.includes("QWI")) {
              let treeItem = {
                id: mods[modType].module + "-" + decCounter,
                name: mods[modType].module,
                icon: myTreeIcons.getTreeIcon2("sen", ""),
                expanded: true,
                children: modOutputsx,
              };

              if (ctdDin.length != 0) {
                ctdDin[ctdId].ctd.children?.push(treeItem);
                ctdmodc++;
              } else {
                kastModuleOutputs.children?.push(treeItem);
              }

              decCounter = decCounter + 1;
            } else if (mods[modType].module.includes("QWZ")) {
              let treeItem = {
                id: mods[modType].module + "-" + decCounter,
                name: mods[modType].module,
                icon: myTreeIcons.getTreeIcon2("sen", ""),
                expanded: true,
                children: modOutputsx,
              };

              if (ctdDin.length != 0) {
                ctdDin[ctdId].ctd.children?.push(treeItem);
                ctdmodc++;
              } else {
                kastModuleOutputs.children?.push(treeItem);
              }

              decCounter = decCounter + 1;

            } else {
              let icon = null;
              if (mods[modType].module.includes("REL")) {
                icon = myTreeIcons.getTreeIcon2("relais", "relais");
              } else {
                icon = outputs[0].icon;
              }

              let treeItem = {
                id: mods[modType].module + "-" + decCounter,
                name: mods[modType].module,
                icon: icon, // mods[modType].modType   Icon for DIN rail module for relais - if handdoek ? -> REL
                expanded: true,
                children: modOutputsx,
              };
              if (ctdDin.length != 0) {
                //debugger
                // Add module to CTDList
                //ctdDin[ctdId].ctd.children?.push(treeItem);
                ctdDin[ctdId].din.children?.push(treeItem);
                
                ctdmodc++;
              } else {
                // Add CTD to kast
                kastModuleOutputs.children?.push(treeItem);
              }

              decCounter = decCounter + 1;
            }
          } else if (DinMod === 0) {
            //debugger
            //if(mods[modType].modType !== "EXP") {
            let treeItem: interfaces.treeItem = {
              id: mods[modType].module + "-" + decCounter,
              name: mods[modType].module,
              icon: outputs[0].icon,
              expanded: true,
              children: modOutputsx,
            };
            decCounter = decCounter + 1;
            //  debugger
            decentraalModuleOutputs.children?.push(treeItem);
            ctdDin[ctdId].dec.children?.push(treeItem);
            //}
          }
          f = f + outpOnMod;
        }
      });
    }
  });

  ctdDin.map((ctds: any) => {
    ctds.ctd.children?.push(ctds.din)
    ctds.ctd.children?.push(ctds.dec)
  });

  //debugger
  ctdDin.map((ctds: any) => {
    kastModuleOutputs.children?.push(ctds.ctd);
    
  });

  let kastTreeItems = {
    kast: kastModuleOutputs,
    //decentraal: decentraalModuleOutputs,
  };

  return kastTreeItems;
}

export function renderKast() {
  const renderTree = (nodes: RenderKastTree) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={nodes.name}
      icon={nodes.icon}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <>
      <Box sx={{ minHeight: 110, flexGrow: 1, maxWidth: 300 }}>
        <TreeView
          aria-label="rich object"
          defaultCollapseIcon={myTreeIcons.DimmerTreeIcon()}
          defaultExpanded={["root"]}
          defaultExpandIcon={myTreeIcons.DimmerTreeIcon()}
        >
          {renderTree(data)}
        </TreeView>

        <TreeView
          aria-label="rich object"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpanded={["root"]}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          {renderTree(decentraal)}
        </TreeView>
      </Box>
    </>
  );
}
