import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
import { Button, CardHeader, Form } from "react-bootstrap";

import "./buildingtree.css";
import * as myTreeIcons from "../../images/icons/treeicons";
import * as newTreeIcons from "../../images/icons/dimmer";
import { ThemeProvider, createTheme } from "@mui/material";

import { useState } from "react";
import { Input } from "@mui/icons-material";

const BuildingTree = (props: any) => {
  let treeItemsArray: string[] = [];
  const [context, setContext] = useState(false);
  const [changeName, setchangeName] = useState(false);
  const [changeIcon, setchangeIcon] = useState(false);

  const [xyPosition, setxyPosition] = useState({ x: 0, y: 0 });

  const [selNode, setSelNode] = useState<myTreeIcons.kamerItem>({
    id: "",
    name: "",
    key: "",
    icon: null,
    expanded: true,
    children: [],
  });

  let currIcon: string = "";

  //let selNode: myTreeIcons.kamerItem

  const getTreeArray = () => {
    treeItemsArray = myTreeIcons.itemArray(props.arr);
    return treeItemsArray;
  };



  function showMenu(event: any, nodes: myTreeIcons.kamerItem) {
    event.preventDefault();
    event.stopPropagation();

    //console.log(nodes.icon.key);
    //console.log(nodes.name);
    //console.log(nodes);
    setSelNode(nodes);

    setContext(false);
    setchangeName(false);
    setchangeIcon(false);

    const positionChangeA = {
      x: event.nativeEvent.offsetX,
      y: event.nativeEvent.offsetY,
    };

    const positionChange = {
      x: event.clientX -75,
      y: event.clientY -150
    };
    

    //set menu position
    setxyPosition(positionChange);

    // show menu
    setContext(true);
  }

  const hideContext = (event: any) => {
    setContext(false);
  };

  const [chosen, setChosen] = useState();
  const initMenu = (chosen: any) => {
    setChosen(chosen);
  };

  function setItem(type: string, nodeId: string, label: string) {
    setContext(false);
    setchangeName(false);
    setchangeIcon(false);
    //console.log(type, nodeId, label);
    if (type === "woning") {
      props.onWoningItemClick(type, nodeId, label);
    }
  }

  let nieweNaam = "";
  function onNaamChange(event: any) {
    //console.log(event.target.value)
    nieweNaam = event.target.value;
  }

  function onNaamKeyPress  (event : any)  {
    if(event.keyCode == 13){
      changeItemName(selNode.id)
    }
  }

  function changeItemName(id: string) {
    setchangeName(false);
    props.onNameChange(id, nieweNaam);
  }

  function removeItem(id: string) {
    setContext(false);
    props.onRemoveItem(id);
  }

  function changeItemIcon(id: string) {
    setchangeIcon(false);
    props.onIconChange(id, currIcon);
  }

  const renderChangeName = () => {
    //setContext(false);
    //console.log(selNode);
    nieweNaam = selNode.name;
    const nameDiv = () => (
      <div
        style={{ top: xyPosition.y, left: xyPosition.x, position: "absolute" }}
        className="chane_name"
      >
        <Form.Control
          type="text"
          id="nieuwenaam"
          name="nieuwenaam"
          aria-describedby=""
          defaultValue={selNode.name}
          onChange={onNaamChange}
          onKeyDown={onNaamKeyPress}
        />

        <Button onClick={() => changeItemName(selNode.id)}> Wijzig naam</Button>
      </div>
    );

    return nameDiv();
  };

  function handleIconChange(eventKey: any) {
    
    //console.log("EK: ", eventKey.target.id);

    switch (eventKey.target.id) {
      case "icon-fan":
        currIcon = "fan";
        break;
      case "icon-rel":
        currIcon = "relais";
        break;
      case "icon-handdoek":
        currIcon = "hand";
        break;
      case "icon-stopc":
        currIcon = "stopc";
        break;
      default:
        currIcon = "relais";
        break;
    }
  }

  const renderChangeIcon = () => {
    //setContext(false);
    //console.log(selNode);
    currIcon = "relais";
    //nieweNaam = selNode.name;
    const nameDiv = () => (
      <div
        style={{ top: xyPosition.y, left: xyPosition.x, position: "absolute" }}
        className="chane_icon"
      >
        {" "}
        <Form onChange={handleIconChange}>
          <Form.Check type="checkbox" id="icon-rel" className="iconCheck">
            <Form.Check.Input type="radio" name="full" defaultChecked={true} />
            <Form.Check.Label className="formLabel">
              <div style={{ height: "50px", width: "50px" }}>
                <newTreeIcons.RelaisIcon />
              </div>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="checkbox" id="icon-fan" className="iconCheck">
            <Form.Check.Input type="radio" name="full"  />
            <Form.Check.Label className="formLabel">
              <div style={{ height: "50px", width: "50px" }}>
                <newTreeIcons.FanIcon />
              </div>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="checkbox" id="icon-handdoek" className="iconCheck">
            <Form.Check.Input type="radio" name="full" />
            <Form.Check.Label className="formLabel">
              <div style={{ height: "50px", width: "50px" }}>
                <newTreeIcons.HanddoekIcon />
              </div>
            </Form.Check.Label>
          </Form.Check>

          <Form.Check type="checkbox" id="icon-stopc" className="iconCheck">
            <Form.Check.Input type="radio" name="full" />
            <Form.Check.Label className="formLabel">
              <div style={{ height: "50px", width: "50px" }}>
                <newTreeIcons.StopcontactkIcon />
              </div>
            </Form.Check.Label>
          </Form.Check>

          <Button onClick={() => changeItemIcon(selNode.id)}>
            {" "}
            Wijzig icoon
          </Button>
        </Form>
      </div>
    );
    //if (selNode.icon.key === "REL") {
    return nameDiv();
    //}
  };

  const renderContext = () => {
    //setContext(false);
    let relNode = false;
    switch (selNode.icon.key) {
      case "REL":
        case "hand":
          case "stopc":
            case "fan":
        relNode = true;
        break;
      default:
        break;
    }

    const nameDiv = () => (
      <div
        style={{
          top: xyPosition.y,
          left: xyPosition.x,
          position: "absolute",
        }}
        className="contextm"
        onClick={hideContext}
      >
        <div className="menuElement" onClick={() => removeItem(selNode.id)}>
          Verwijder
        </div>
        <div className="menuElement" onClick={() => setchangeName(true)}>
          Hernoem
        </div>
        {relNode ? (
          <div className="menuElement" onClick={() => setchangeIcon(true)}>
            Wijzig icoon
          </div>
        ) : (
          <></>
        )}
      </div>
    );

    return nameDiv();
  };

  const renderTreeItems = () => {
    //debugger
    const arr: myTreeIcons.kamerItem = props.arr;

    //console.log(props.arr)

    const renderTree = (nodes: myTreeIcons.kamerItem) => (
      <TreeItem
        key={nodes.id}
        nodeId={nodes.id}
        label={nodes.name}
        icon={nodes.icon}
        onClick={() => setItem(nodes.icon.key, nodes.id, nodes.name)}
        //onClick= {() => onClick1()}

        onContextMenu={(e) => showMenu(e, nodes)}
        onDoubleClick={(e) => showMenu(e, nodes)}
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node: myTreeIcons.kamerItem) =>
              renderTree(node)
            )
          : null}
      </TreeItem>
    );

    //console.log("ARR: ", arr);

    return renderTree(arr);
  };

  const renderTreeItems1 = () => {

    const CustomFontTheme = createTheme({
      typography: {
        fontSize: 11,
      },
    });

    const renderTree = () => (
      <>
        <ThemeProvider theme={CustomFontTheme}>
          {renderTreeItems()}
        </ThemeProvider>
      </>
    );

    return renderTree();
  };

  return (
    <>
      <Card className="card_buildingtree" style={{ height: "95vh" }}>
        <CardHeader>
          <Nav>
            <Nav.Item>
              <Nav.Link className="building-nav">Woning</Nav.Link>
            </Nav.Item>
          </Nav>
        </CardHeader>

        {changeName ? renderChangeName() : <></>}
        {changeIcon ? renderChangeIcon() : <></>}
        {context ? renderContext() : <></>}

        <TreeView
          aria-label="customized"
          expanded={getTreeArray()}
          sx={{ overflowX: "hidden" }}
        >
          {renderTreeItems1()}
        </TreeView>
      </Card>
    </>
  );
};

export default BuildingTree;
