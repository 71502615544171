import { useRef, useState, useEffect, useContext } from 'react';
import AuthContext from "./context/AuthProvider"
import { loadDatabases } from './../database/databaseLoader'

import axios from './api/axios';
import { useNavigate } from 'react-router-dom';
import './register.css'

const LOGIN_URL = '/auth';


const Login = (props) => {
  const navigate = useNavigate();

  const { setAuth } = useContext(AuthContext);
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("site") || "");

  const [loading, setLoading] = useState(false);
  useEffect(() => {

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  async function Ldb() {
    //UpdateProject2(data, name, klant, id).then((data: any) => {
    loadDatabases().then((data) => {
      setLoading(false)
      setSuccess(true);
    });

  }
  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd]);

//const [cookies, setCookie] = useCookies(['access_token', 'refresh_token'])

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ user, pwd }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
         // Cookie: 'cookie1=value; '
        }
      );

     
/*
          let expires = new Date()
         expires.setTime(expires.getTime() + (response.data.expires_in * 1000))
        // setCookie('access_token', response.data.accessToken, { path: '/',  expires})
         setCookie('refresh_token', response.data.refresh_token, {path: '/', expires})
*/
       //  let decodedToken = jwtDecode(response.data.accessToken);
       //  debugger

      const accessToken = response?.data?.accessToken;
      const id = response?.data?.userid;
      const r = response?.data?.r;

      setAuth({ user, pwd, r, accessToken });

      setToken(accessToken);
      localStorage.setItem("site", accessToken);

      setUser('');
      setPwd('');



      { props.onSetToken(user, pwd, r, accessToken, id) }

      setLoading(true);

      await Ldb();


    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Username or Password');
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized');
      } else {
        setErrMsg('Login Failed');
      }
      errRef.current.focus();
    }
  };

  return (

    <div className="textColor">
      {loading ? (
        <div className="loader-container">
          <div className="spinner">Even geduld aub, we laden de gegevens uit de database.</div>
        </div>
      ) : (<></>)}

      {success ? (

        navigate('/')
      ) : (
        <section>
          <p
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <h1>Aanmelden</h1>
          <form onSubmit={handleSubmit}>
            <label htmlFor="username">Gebruikersnaam:</label>
            <input
              type="text"
              id="username"
              ref={userRef}
              autoComplete="off"
              onChange={(e) => setUser(e.target.value)}
              value={user}
              required
            />
            <label htmlFor="password">Wachtwoord:</label>
            <input
              type="password"
              id="password"
              onChange={(e) => setPwd(e.target.value)}
              value={pwd}
              required
            />
            <br></br>
            <button className='colorButton'>Aanmelden</button>
          </form>
          <p>
            Een account nodig?
            <br />
            <span className="line">
              <a href="/register">Registreer</a>
            </span>
          </p>
        </section>
      )}
    </div>
  );
};

export default Login;
/*
export const useAuth = () => {
  return useContext(AuthContext);
};
*/