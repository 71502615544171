import React, { Component, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import NavBar from "./Navbar/Navbar";

import Register from "../src/login/Register";
import AdminPanel from "./admin/admin";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../src/login/Login";
import { useCookies } from "react-cookie";

import { AuthProvider } from "./login/context/AuthProvider";

type MyProps = {};
type MyState = {
    calculating: boolean;
};

class App extends Component<MyProps, MyState> {
  constructor(props: MyState) {
    super(props);

    // Set initial state
    this.state = {calculating: false};

    // Binding this keyword
    this.setUser = this.setUser.bind(this);
   // this.startCalculating = this.startCalculating.bind(this)
    //this.stopCalculating = this.stopCalculating.bind(this)
    //sessionStorage.clear();
  }

  //calculating = false;

  setUser(
    user: string,
    password: string,
    roles: string,
    accessToken: string,
    id: number
  ) {
    //console.log("setting roles: ",roles);

    /*
        for (var key in JSON.parse(roles)) {
            //console.log(key);
            console.log(roles[key]);
        }

*/

    sessionStorage.setItem("user", JSON.stringify(user));
    sessionStorage.setItem("accessToken", JSON.stringify(accessToken));
    sessionStorage.setItem("status", JSON.stringify(true));
    sessionStorage.setItem("userid", JSON.stringify(id));
    sessionStorage.setItem("role", roles);

    sessionStorage.setItem("nodeId", "5");

    sessionStorage.setItem("uitvoeringText", "Niko - White 101 RGB");
    sessionStorage.setItem("uitvoering", "101");

    sessionStorage.removeItem("projectId");
    sessionStorage.removeItem("eindklant");
    sessionStorage.removeItem("eindklantId");
    sessionStorage.removeItem("bestandsnaam");
    sessionStorage.removeItem("woningTree");
    sessionStorage.removeItem("loadedWoning");
    sessionStorage.removeItem("offerteModules");

  }

  handleLoadedWoningChange(arr: any) {
    //console.log("APP ARR: ", arr)
  }
/*
  setCalculating = () => {
    //this.calculating = true;
    this.setState({calculating: true})
  };

  reSetCalculating = () => {
    //this.calculating = false;
    this.setState({calculating: false})
  };

  startCalculating() {
    this.setCalculating();
  }

  stopCalculating() {
    //debugger
    this.reSetCalculating();
  }
*/
  render() {
    return (
      <Container className="AppContainer">
        <main className="App">
          <Router>
            <Routes>
              <Route path="/register" element={<Register />} />
              <Route
                path="/login"
                element={<Login onSetToken={this.setUser} />}
              />
              <Route
                path="/"
                element={
                  <NavBar
                    //calculating={this.state.calculating}
                    onLoadedWoningChange={this.handleLoadedWoningChange}
                    //startCalc={this.startCalculating}
                    //stopCalc={this.stopCalculating}
                  />
                }
              />
              <Route path="/admin" element={<AdminPanel />} />
            </Routes>
          </Router>
        </main>
      </Container>
    );
  }
}

export default App;
