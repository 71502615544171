import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

interface MyState {}

interface MyProps {
  visible: boolean;
  onDmxClose: (tdmxVisible: boolean, id: number) => any;
  onDmxSelect: (t: any) => any;
}

class DmxOptions extends Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
  }

  dmxChoise = 0

  handleClose = () => {
    this.props.onDmxClose(false, this.dmxChoise);
  };

  handleChange = (event: any) => {
    //console.log(event.target);
    this.dmxChoise = event.target.value;
    //this.props.onDmxSelect(event.target.value)
  };

  render() {
    return (
      <Modal show={this.props.visible === true ? true : false}>
        <Modal.Header>
          <Modal.Title>
            <h1> DMX </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormControl className="projectsFrom">
            <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              className="customCardName"
              onChange={this.handleChange}
            >
              <FormControlLabel
                key={"dmx1"}
                value={1}
                control={<Radio />}
                label={"1 uitgang"}
              />

              <FormControlLabel
                key={"dmx2"}
                value={6}
                control={<Radio />}
                label={"6 uitgangen (RGB + cloud)"}
              />

              <FormControlLabel
                key={"dmx3"}
                value={8}
                control={<Radio />}
                label={"8 uitgangen (RGBW + cloud)"}
              />

              <FormControlLabel
                key={"dmx4"}
                value={4}
                control={<Radio />}
                label={"4 uitgangen (WWKW + cloud)"}
              />
            </RadioGroup>
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={this.handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DmxOptions;
