import { Component, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
import { CardBody, CardHeader, Form, Row, Toast } from "react-bootstrap";
import { Button, Table } from "react-bootstrap";
import * as functions from "./overzichtFunctions";
import * as kastFunctions from "././kastopbouw";

import * as dbFunctions from "../../database/modules";
import * as info from "./info";
import "./overzicht.css";

import * as interfaces from "./sharedInterfaces";
import {
  Box,
  TextField,
  ThemeProvider,
  createTheme,
  Theme,
  useTheme,
  styled,
} from "@mui/material";
import { TreeView } from "@mui/x-tree-view/TreeView";
import * as myTreeIcons from "../../images/icons/treeicons";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeItem } from "@mui/x-tree-view/TreeItem";

interface offerteItem {
  module: string;
  aantal: number;
}

interface RenderKastTree {
  id: string;
  name: string;
  icon: string;
  children?: readonly RenderKastTree[];
}

/*

interface offerte {
  items: offerteItem;
}

interface mods {
  [key: string]: any;
    value: number;
}
*/
interface mods1 {
  module: string;
  aantal: number;
  prijs: number;
  vrij: number;
  modType: string;
  modId: number;
  sa: number;
}

interface modsArray {
  mods: Array<mods1>;
}

interface MyProps {
  arr: any;
  db: any;
  uitvoering: string;
  ctd: string;
  full: boolean;
  paketten: boolean;
  indienst: boolean;
  rel04_08: boolean;
  onDataChange: (items: any, saitems: any) => any;
  onInDienstChanged: (t: any) => any;
  // stopCalc: () => any;
}

interface MyState {
  selectedView: string;
  offerte: offerteItem[];
  calculating: boolean;
  indienst: boolean;
}

class Overzicht extends Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      calculating: false,
      selectedView: "kast",
      offerte: [{ module: "", aantal: 0 }],
      indienst: false,
    };
  }

  myofferte: offerteItem[] = [];

  OffTotaal: number = 0;
  indienstChecked = false;
  //uitvoering: string = ""

  calcKast:interfaces.treeItem = {
    id: "Kast",
    name: "Kast",
    icon: null,
    expanded: true,
    children: [],
  };

  componentDidMount(): void {
    //this.getModules2();
  }

  handleSelect = (eventKey: any) => {
    if (`${eventKey}` === "kast") {
      this.setState({ selectedView: "kast" });
    } else if (`${eventKey}` === "offerte") {
      this.setState({ selectedView: "offerte" });
    } else if (`${eventKey}` === "sa-offerte") {
      this.setState({ selectedView: "sa-offerte" });
    } else if (`${eventKey}` === "info") {
      this.setState({ selectedView: "info" });
    }
  };

  setLoading = (calc: boolean) => {
    this.setState({ calculating: calc });
  };

  stopCalc = () => {
    //this.props.stopCalc()
  };

  handleIndienstCheck = (eventKey: any) => {
    this.setState({ indienst: eventKey.target.checked });
    this.props.onInDienstChanged(eventKey.target.checked);
  };

  RenderedOfferte = () => {
    //console.log("Render offerte");
    //debugger
    const offerteItemsArray = functions.calculate(
      this,
      false,
      this.props.rel04_08,
      true
    );
    const noSaOfferteItemsArray: functions.modsArray2[] = [];
    this.props.onDataChange(offerteItemsArray, noSaOfferteItemsArray);
    const offerteItems = offerteItemsArray.mods;
    let totaal = 0;
    let totIndienst = 0;
    let itemToRemove = "";

    offerteItems.map((item) => {
      if (item.pakket != 0 && this.props.paketten === true) {
        let modPakket = functions.GetModById(item.pakket);
        let modInfo = functions.GetModById(item.modId);

        const aantalPakketten = Math.floor(item.aantal / modPakket.outputs);
        const overschot = item.aantal - aantalPakketten * modPakket.outputs;
        const vrijeOutpOnPakket = 0;

        if (aantalPakketten >> 0) {
          // Add Pakket (aantal=)
          let pakket: functions.mods2 = {
            module: modPakket.name,
            aantal: aantalPakketten,
            prijs: modPakket.prijs,
            vrij: vrijeOutpOnPakket,
            modType: "",
            modId: 0,
            sa: modPakket.sa,
            pakket: 0,

            //totoutp: 0,
          };
          offerteItems.push(pakket);
          item.aantal = overschot;
          if (overschot == 0) {
            // remove item from items
            itemToRemove = item.module;
            pakket.vrij = item.vrij;
          }
        }
        //
      }
    });

    let newArray = offerteItems
      let indienst = functions.GetModById(180);
      let indienstprijs = indienst.prijs
    newArray.map((item) => {
        let modInfo = functions.GetModById(item.modId);
      if (modInfo.busConsumption > -1) {
        totIndienst = totIndienst + (item.prijs * item.aantal);
        
        totIndienst = +totIndienst.toFixed(2);
      }


      totaal = totaal + item.prijs * item.aantal;
      totaal = +totaal.toFixed(2);
      
    });

    this.OffTotaal = totaal;

    let aantInd = +(totIndienst / 1000).toFixed(2);
    aantInd = aantInd * indienstprijs

    return (
      <>
        <Table striped bordered hover style={{ fontSize: 14 }}>
          <thead>
            <tr>
              <th>Module</th>
              <th>Aantal</th>
              <th>Prijs</th>
              <th>Totaal</th>
              <th>Vrije</th>
            </tr>
          </thead>
          <tbody>
            {newArray.map((offerteItem: mods1) => {
              return (
                <tr key={offerteItem.module}>
                  <td>{offerteItem.module}</td>
                  <td>{offerteItem.aantal}</td>
                  <td>{offerteItem.prijs}</td>
                  <td>{offerteItem.prijs * offerteItem.aantal}</td>
                  <td>{offerteItem.vrij}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>

        <Form.Check
          type="checkbox"
          id="check-api-checkbox4"
          className="formCheck_overzicht"
        >
          <Form.Check.Input
            type="checkbox"
            defaultChecked={this.props.indienst}
            name="indienst"
            onChange={this.handleIndienstCheck}
          />
          <Form.Check.Label className="formLabel_overzicht">
            Voeg indienst toe
          </Form.Check.Label>
        </Form.Check>

        <div className="totals">
          Totaalprijs: €{totaal} <br></br>
          {this.state.indienst ? (
            <>
              Indienst: €{aantInd}
            </>
          ) : null}
        </div>
      </>
    );
  };

  RenderSaOfferte = () => {

    //this.setState({indienst: false})
    
    const offerteItemsArray = functions.calculateSA(
      this,
      true,
      this.props.rel04_08,
      true
    );
    //let saOfferteItemsArray:functions.modsArray2[] = []

    //this.props.onDataChange(offerteItemsArray, saOfferteItemsArray);
    //debugger
    //debugger
    //const offerteItemsArray: modsArray = functions.calculateSA(this);
    //functions.calculateSA(this);
    const offerteItems = offerteItemsArray.mods;
    let totaal = 0;

    let oi = offerteItems;
    let itemToRemove = "";

    let saMods: string[] = [];

    offerteItems.map((item) => {
      if (item.sa == 1) {
        if (item.pakket != 0 && this.props.paketten === true) {
          let modPakket = functions.GetModById(item.pakket);
          let modInfo = functions.GetModById(item.modId);

          const aantalPakketten = Math.floor(item.aantal / modPakket.outputs);
          const overschot = item.aantal - aantalPakketten * modPakket.outputs;
          const vrijeOutpOnPakket = 0;

          if (aantalPakketten >> 0) {
            // Add Pakket (aantal=)
            let pakket: functions.mods2 = {
              module: modPakket.name,
              aantal: aantalPakketten,
              prijs: modPakket.prijs,
              vrij: vrijeOutpOnPakket,
              modType: "",
              modId: 0,
              sa: modPakket.sa,
              pakket: 0,
              //totoutp: 0,
            };
            offerteItems.push(pakket);
            item.aantal = overschot;
            if (overschot == 0) {
              // remove item from items
              itemToRemove = item.module;
              pakket.vrij = item.vrij;
            }
          }
        }
        saMods.push(item.modType);
      }
    });

    //debugger
    let newArray = offerteItems
      .filter((item) => item.module !== itemToRemove)
      .map((item) => item);

    newArray.map((item) => {
      if (item.sa == 1) {
        totaal = totaal + item.prijs * item.aantal;
        totaal = +totaal.toFixed(2);
      }
    });

    const noSaOfferteItemsArray = functions.calculate(
      this,
      false,
      this.props.rel04_08,
      false
    );

    let newArray2 = noSaOfferteItemsArray.mods;

    let a: mods1[] = [];

    let retMods: modsArray = { mods: [] };

    
    newArray2.map((item) => {
      let m1 = item.modType;

      const mod = !!saMods.find((m2) => {
        return m1 === m2;
      });
      if (mod === false) {
        a.push(item);
        retMods.mods.push(item)
      }
    });

    

    this.props.onDataChange(offerteItemsArray, retMods);

    this.OffTotaal = totaal;

    let aantInd = +(this.OffTotaal / 100).toFixed(2);

    return (
      <>
        <Table striped bordered hover style={{ fontSize: 14 }}>
          <thead>
            <tr>
              <th>Module</th>
              <th>Aantal</th>
              <th>Prijs</th>
              <th>Totaal</th>
              <th>Vrije</th>
            </tr>
          </thead>
          <tbody>
            {newArray.map((offerteItem: mods1) => {
              if (offerteItem.sa == 1) {
                return (
                  <tr key={offerteItem.module}>
                    <td>{offerteItem.module}</td>
                    <td>{offerteItem.aantal}</td>
                    <td>{offerteItem.prijs}</td>
                    <td>{offerteItem.prijs * offerteItem.aantal}</td>
                    <td>{offerteItem.vrij}</td>
                  </tr>
                );
              }
            })}
          </tbody>
        </Table>
        <div className="totals">
          Totaalprijs: €{totaal} <br></br>
        </div>
        <br></br>
        Onderstaande modules hebben we niet in Stand Alone uitvoering:
        <Table striped bordered hover style={{ fontSize: 14 }}>
          <thead>
            <tr>
              <th>Module</th>
              <th>Aantal</th>
              <th>Prijs</th>
              <th>Totaal</th>
              <th>Vrije</th>
            </tr>
          </thead>
          <tbody>
            {a.map((offerteItem: mods1) => {
              if (offerteItem.sa != 1) {
                return (
                  <tr key={offerteItem.module}>
                    <td>{offerteItem.module}</td>
                    <td>{offerteItem.aantal}</td>
                    <td>{offerteItem.prijs}</td>
                    <td>{offerteItem.prijs * offerteItem.aantal}</td>
                    <td>{offerteItem.vrij}</td>
                  </tr>
                );
              }
            })}
          </tbody>
        </Table>
      </>
    );
  };

  RenderKast = () => {
    //console.log("Render kast");
    const offerteItemsArray = functions.calculate(
      this,
      false,
      this.props.rel04_08,
      false
    );
    const kastmap = kastFunctions.CalculateKast(
      offerteItemsArray,
      //this.props.arr,
      false,
      this.props.db
    );
    
    this.calcKast = kastmap.kast

    const CustomFontTheme = createTheme({
      typography: {
        fontSize: 11,
      },
    });

    const renderTree = (nodes: RenderKastTree) => (
      <ThemeProvider theme={CustomFontTheme}>
        <TreeItem
          key={nodes.id}
          nodeId={nodes.id}
          label={nodes.name}
          icon={nodes.icon}
        >
          {Array.isArray(nodes.children)
            ? nodes.children.map((node) => renderTree(node))
            : null}
        </TreeItem>
      </ThemeProvider>
    );

    //this.setState({calculating: false});
    //this.setLoading(false)

    return (
      <>
        <Box sx={{ minHeight: 110, flexGrow: 1, maxWidth: 500 }}>
          <TreeView
            aria-label="rich object"
            defaultCollapseIcon={myTreeIcons.getTreeIcon2(
              "electric",
              "electric"
            )}
            defaultExpanded={["root"]}
            defaultExpandIcon={myTreeIcons.getTreeIcon2("electric", "electric")}
          >
            {renderTree(kastmap.kast)}
          </TreeView>
        </Box>
      </>
    );
  };

  RenderInfo = () => {
    //console.log("Render info");
    const offerteItemsArray = functions.calculate(
      this,
      false,
      this.props.rel04_08,
      false
    );
    let totAdresses = 0;
    
    //let ctds: interfaces.ModuleTypes[] = []
    //modArray

   // offerteItemsArray.mods.map((item) => {

   // });
/*
    offerteItemsArray.mods.map((item:functions.mods2) => {
      console.log("item: ", item)
      busco = item.busCons * item.aantal
    });
*/
    // Calculate addresses by ctd
    this.props.db.map((item: interfaces.dbItem) => {
      //console.log(item);
      const type = item.type;
      const parameters = functions.getModuleParametersByType(type);
      //console.log(parameters)
      const adresses = parameters.adresses;

      let dmxT = "";
      if (type.hasOwnProperty("outputOptions")) {
        const outputOptions = item.outputOptions;
        dmxT = outputOptions.DmxType;
      }

      let dmxA = 0;

      if (dmxT) {
        switch (dmxT) {
          case "1":
            dmxA = 1;
            break;
          case "6":
            dmxA = 10;
            break;
          case "8":
            dmxA = 10;
            break;
          case "4":
            dmxA = 10;
            break;
        }
        totAdresses = totAdresses + dmxA;
      } else {
        totAdresses = totAdresses + adresses;
      }
    });

    // Calculate addresses by ctd

   // console.log(this.calcKast)

    const controllerCounter = this.calcKast.children?.length
    //console.log(controllerCounter)
    let ctdBusCons = 0
    let ctdAdresses = 0

    this.calcKast.children?.forEach((ctd) => {
      ctdBusCons = 0
      ctdAdresses = 0

      ctd.children?.forEach((dindec) => {
        dindec.children?.forEach((module) => {
          //debugger
          const modT = module.name
          //let outpOnMod:number = 1
          let outpOnMod:number = module.children?.length || 1
          const modparams = functions.getModuleParameters(modT)
          ctdAdresses = ctdAdresses + (modparams.adresses * outpOnMod)
          ctdBusCons = ctdBusCons + modparams.busConsumption

        });
      });

     // console.log(ctd.name + " - " + ctdAdresses + " - " + ctdBusCons);
    });


    //debuggerconsole.log(this.calcKast)
    /*
    offerteItemsArray.mods.forEach((mod) => {
      const modinfo = functions.GetModById(mod.modId);
debugger
     // const type = modinfo.type;
      //if (type === "CTD"){
      //  ctds.push(modinfo)
      //}

      //const adresses = modinfo.adresses;
      //const outputs = modinfo.outputs;
      //const outsVrij = mod.vrij;
      //const aantMods = mod.aantal;
      //debugger
      //const adressesForThisMod =
      //  aantMods * adresses - (adresses / outputs) * outsVrij;

      //totAdresses = totAdresses + adressesForThisMod;
    });
*/
    return (
      <div>
        Type uitvoering: {sessionStorage.getItem("uitvoering")} <br></br>
        Aantal gebruikte adressen: {totAdresses}
        <br></br>
        Totaal busverbruik: <br></br>
      </div>
    );

    /*
           Controller(s): {Array.isArray(ctds)
            ? ctds.map((ss) => <> {ss.name} </>)
            : null}<br></br> 
    */
  };

  render() {
    //const offerteItemsArray = functions.calculate(this, false, this.props.rel04_08);

    return (
      <Card id="card_overzicht" style={{ height: "95vh" }}>
        <CardHeader>
          <Nav onSelect={this.handleSelect}>
            <Nav.Item>
              <Nav.Link eventKey="kast" className="building-nav">
                Kast
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="offerte" className="building-nav">
                Full Offerte
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="sa-offerte" className="building-nav">
                SA Offerte
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="info" className="building-nav">
                Info
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </CardHeader>
        <CardBody style={{ overflow: "auto" }}>
          {this.state.selectedView === "kast" ? this.RenderKast() : ""}

          {this.state.selectedView === "offerte" ? this.RenderedOfferte() : ""}
          {this.state.selectedView === "sa-offerte"
            ? this.RenderSaOfferte()
            : ""}
          {this.state.selectedView === "info" ? this.RenderInfo() : ""}
        </CardBody>
      </Card>
    );
  }
}
export default Overzicht;
