import * as interfaces from "./sharedInterfaces";

// Get modules from database
// Return module and outputs counter
export function getModule(moduleType: string, onlySa: boolean): {
    retModules: interfaces.ModuleTypes[];
    retCounter: number;
  } {
    const retModules: interfaces.ModuleTypes[] = [];
    let retCounter: number = 0;
  
    var storedArray: interfaces.ModuleTypes[] = JSON.parse(
      sessionStorage.getItem("DbModules") || "{}"
    );
    //console.log("jmods2: ", storedArray)
  //debugger
    // modules from database
    storedArray.map((module) => {
      if (module.type === moduleType) {
        
        if(onlySa == false){
          retModules.push(module);
          retCounter = retCounter + module.outputs;
        } else {
          if (module.sa == 1) {
            retModules.push(module);
            retCounter = retCounter + module.outputs;
          }
        }

        if (moduleType === "CTD"){
          retModules.push(module);
          retCounter = retCounter + module.outputs;
        }
        
        //console.log("RET: ", retCounter)
        //console.log("MOD: ", module.outputs)
      }
    });
  
    var sortedModules: interfaces.ModuleTypes[] = retModules.sort(
      (n1, n2) => n2.outputs - n1.outputs
    );
    const retdata = { retModules: sortedModules, retCounter: retCounter };
    //console.log(retdata);
    return retdata;
  }

  export function getCtds() {
    const dbMods: interfaces.ModuleTypes[] = JSON.parse(
      sessionStorage.getItem("DbModules") || "{}"
    );
    // Find module in modules database
    //console.log("DBMods: ", dbMods)
    let ctds = [{}];
  
    if (dbMods) {
      dbMods.map((mod: interfaces.ModuleTypes) => {
        if (mod.name.includes("CTD")) {
          //const buscons = mod.busConsumption
          ctds.push(mod);
        }
      });
    }
    // console.log ("findmod/ ", retMod)
    return ctds;
  }