import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";

import "./infobar.css";

import { Form } from "react-bootstrap";

import { CSSProperties } from "react";
import DotLoader from "react-spinners/BarLoader";
const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};


interface MyProps {
  onFullChanged: (t: any) => any;
  onInDienstChanged: (t: any) => any;
}

const Infobar = (props: MyProps) => {

  let indienstChecked = false;

  const handleFullCheck = (eventKey: any) => {
    props.onFullChanged(eventKey.target.checked);
  };

  const handleIndienstCheck = (eventKey: any) => {
    props.onInDienstChanged(eventKey.target.checked);
  };

  const ShowLoading = () => {
    return (
      <div className="sweet-loading">
        <DotLoader
          color={"#F3BA0D"}
          loading={true}
          cssOverride={override}
          height={10}
          width={300}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  };
  //{this.props.calculating === true ? this.ShowLoading() : ""}

    return (
      <div className="bar">
        <Stack direction="horizontal" gap={2}>
          <Badge bg="secondary">
            Uitvoering toetsen: {sessionStorage.getItem("uitvoeringText")}
          </Badge>
          <Badge bg="secondary">
            Klant: {sessionStorage.getItem("eindklant")}
          </Badge>
          <Badge bg="secondary">
            Project: {sessionStorage.getItem("bestandsnaam")}
          </Badge>

          

          <Form.Check
            type="checkbox"
            id="check-api-checkbox4"
            className="formCheck"
          >

            <Form.Check.Label className="formLabel" style={{ width: "100px" }}>
              
            </Form.Check.Label>
          </Form.Check>


        </Stack>
      </div>
    );

}
export default Infobar;
