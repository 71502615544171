interface ModuleTypes {
  id: number;
  type: string;
  name: string;
  outputs: number;
  nextModule: number;
  parentModule: number;
  inputs: number;
  busConsumption: number;
  din: number;
  icon: string;
  prijs: number;
}

export function checkMod(type: string) {
  let found: boolean = false;
  let parent: number = 0;
  const dbMods: ModuleTypes[] = JSON.parse(
    sessionStorage.getItem("DbModules") || "{}"
  );
  if (dbMods) {
    dbMods.map((mod: ModuleTypes) => {
      if (mod.type === type) {
        
        found = true;
        parent = mod.parentModule;
      }
    });
  }
  const retMod = { found: found, parentModule: parent };
  return retMod;
}

export function getModById(id: number) {
  let retmod:any = {}

  const dbMods: ModuleTypes[] = JSON.parse(
    sessionStorage.getItem("DbModules") || "{}"
  );
  if (dbMods) {
    dbMods.map((mod: ModuleTypes) => {
      if (mod.id === id) {
        retmod = mod
      }
    });
  }
  return retmod
}

export function checkParentInDb(parentid: number) {
  const typeToCheck = getModById(parentid)
  const woningDb = JSON.parse(sessionStorage.getItem("loadedWoning") || "{}");
  const offerteDb = JSON.parse(sessionStorage.getItem("offerteModules") || "{}");
 // debugger

  
  if (Object.keys(offerteDb).length > 0 ) {
    offerteDb.mods.map((offerteDbMod:any) => {
      //debugger
      if (offerteDbMod.modType === typeToCheck) {
        //console.log(offerteDbMod)
        offerteDbMod.vrij = offerteDbMod.vrij - 1;
      }
    });
  }
  sessionStorage.setItem("offerteModules",JSON.stringify(offerteDb));
}
