import axios from "../login/api/axios"

const MODULES_URL = '/modules';
const EXTENDED_MODULES_URL = '/allextendedmodules';
const UPDATEMOD_URL = '/updatemodules';

export interface modulesFromDatabase  {
    success: [
      {
        id: number;
        type: string;
        name: string;
        outputs: number;
        inputs: number;
        nextModule: number;
        parentModule: number;
        busConsumption: number;
        din: number;
        icon: string;
        createdAt: string;
        updatedAt: string;
      }
    ]
  }


export async function getModulesFromDatabase() {
 const getModulesFromDatabase = new Promise<modulesFromDatabase>((resolve, reject) => {
    const accessToken = sessionStorage.getItem('accessToken')
    //console.log(sessionStorage.getItem('accessToken'))
    axios.get(
      MODULES_URL,
        {
          headers: { 
            'Authorization' : 'Bearer ' + accessToken,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
    )
    .then(res => {
      if (res) {
        resolve (res.data)
      } else {
        reject ("DB No modules found")
      }
      })
    .catch(error => {
      console.log ("Error: DB No modules found: " + error)
    })
  }); 
  const ModulesFromDatabase = await getModulesFromDatabase
  
  return ModulesFromDatabase
} 

export async function getExtendedModuleFromDatabase() {
  const getExtendedModuleFromDatabase = new Promise<modulesFromDatabase>((resolve, reject) => {
     const accessToken = sessionStorage.getItem('accessToken')
     axios.get(
      EXTENDED_MODULES_URL,
         {
           headers: { 
             'Authorization' : 'Bearer ' + accessToken,
             "Content-Type": "application/json",
           },
           withCredentials: true
         }
     )
     .then(res => {
       if (res) {
        //console.log(res)
         resolve (res.data)
       } else {
         reject ("DB No modules found")
       }
       })
     .catch(error => {
       console.log ("Error: DB No modules found: " + error)
     })
   }); 
   const ExtendedModuleFromDatabase = await getExtendedModuleFromDatabase
   return ExtendedModuleFromDatabase
 } 

 export function UpdateModule(id:number,type:string, outputs:number, nextModule:number, parentModule:number, inputs:number, busConsumption:number, adresses: number,din:number, offCat: number)  {
  const accessToken = sessionStorage.getItem('accessToken')
  const handleUpdateModule = async () => {
      try {
          const response = await axios.post(
            UPDATEMOD_URL,
            JSON.stringify({ id, type, outputs, nextModule,parentModule, inputs, busConsumption, adresses, din, offCat }),
            {
              headers: { 
                  'Authorization' : 'Bearer ' + accessToken,
                  "Content-Type": "application/json",
                },
                withCredentials: true,
            }
          )
          return response
      } catch (err) {
          console.log(err)
      }
  };
  return handleUpdateModule();
}