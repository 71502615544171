import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Table } from "react-bootstrap";

import { getCustomerById } from "../database/userInfo";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import "./projects.css";

interface projects {
  id: number;
  userid: string;
  name: string;
  customerId: number;
}

interface customer {
  id: number;
  user_id: number;
  naam: string;
  voornaam: string;
}

interface projectFromDatabase {
  success: [
    {
      id: number;
      userid: string;
      name: string;
      customerId: number;
    }
  ];
}

interface MyState {
  selectedRow: number;
}

interface MyProps {
  visible: string;
  projects: any;
  onDataChange: (t: any) => any;
  onOpenProject: (id: number) => any;
}

class Projects extends Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {selectedRow: 0}
  }

  vstate = { settings: "hidden" };
  projToOpen: string = "0";
  projToOpenName = "";

  handleClose = () => {
    this.vstate.settings = "hidden";
    this.props.onDataChange(this.vstate);
  };

  setName(id: number) {
    const p = this.props.projects;
    p.success.map((offerteItem: projects) => {
      if (offerteItem.id === id) {
        sessionStorage.setItem("bestandsnaam", offerteItem.name);
      }
    });
  }

  handleOpen = () => {
    this.vstate.settings = "hidden";
    this.props.onDataChange(this.vstate);
    this.props.onOpenProject(parseInt(this.projToOpen));
    this.setName(parseInt(this.projToOpen));
  };

  //selectedRow = 0

  setProject = (row: any) => {
    //console.log(row.target.parentNode.id)
    //this.selectedRow = row.target.parentNode.id
    this.projToOpen = row.target.parentNode.id
    this.setState({selectedRow: Number(row.target.parentNode.id)})

  }

  // Find custommer by ID in loaded Custommers database
  findCustommer = (id: number) => {
    let cus = JSON.parse(sessionStorage.getItem("DbCustommers") || "");
    const cust = cus.filter((custommer: customer) => custommer.id === id);
    
    return cust;
  };

  
  renderProjectsList(projectArray: projectFromDatabase) {
  
    const handleChange = (event: any) => {
      //console.log(event.target);
      this.projToOpen = event.target.value;
      this.projToOpenName = event.target.label;
    };

    // Build projects array - Add custommer name

    let projects = [{ id: 0, project: "", custommer: "" }];
    projects.pop();

    if (projectArray.success) {
      projectArray.success.map((offerteItem) => {
        const id = offerteItem.id
        const name = offerteItem.name;
        const custommer = this.findCustommer(offerteItem.customerId);
        let c = "";
        if (custommer.length !== 0) {
          c = custommer[0].naam;
        }
        projects.push({ id: id, project: name, custommer: c });
      });
    }

    const projectsList = () => {
      return (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Project</th>
                <th>Klant</th>
              </tr>
            </thead>

            {projects.map((offerteItem) => {
              return (
                <tr id={String(offerteItem.id)} onClick={this.setProject} className={"clickable-row ".concat(this.state.selectedRow === offerteItem.id ? "selected" : "")}>
                  <td>{offerteItem.project}</td>
                  <td>{offerteItem.custommer}</td>
                </tr>
              );
            })}
          </Table>
        </>
      );
    };

    return projectsList();
  }
  
  render() {
    return (
      <Modal show={this.props.visible === "open" ? true : false} dialogClassName="modal_projects">
        <Modal.Header closeButton onClick={this.handleClose}>
          <Modal.Title>
            <h1> Projects</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="projectsOptions">
          {this.renderProjectsList(this.props.projects)}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={this.handleOpen}>
            Open project
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default Projects;
