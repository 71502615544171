import React, { Component } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import * as interfaces from './../Opbouw/Overzicht/sharedInterfaces'
import { UpdateModule, getModulesFromDatabase } from "../database/modules";

interface ModuleTypes {
  id: number;
  type: string;
  name: string;
  outputs: number;
  nextModule: number;
  parentModule: number;
  inputs: number;
  busConsumption: number;
  adresses: number;
  din: number;
  icon: string;
  prijs: number;
  offCat: number;
}

interface MyState {}

interface MyProps {}

class AdminProductTable extends Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
  }

  createData(
    id: number,
    type: string,
    name: string,
    outputs: number,
    nextModule: number,
    parentModule: number,
    inputs: number,
    busConsumption: number,
    adresses: number,
    din: number,
    icon: string,
    prijs: number,
    offCat: number,
  ) {
    return {
      id,
      type,
      name,
      outputs,
      nextModule,
      parentModule,
      inputs,
      busConsumption,
      adresses,
      din,
      icon,
      prijs,
      offCat
    };
  }

  rows = [
    this.createData(
      1,
      "Frozen yoghurt",
      "Frozen yoghurt",
      1,
      2,
      3,
      5,
      6,
      4,
      5,
      "12",
      6,
      3
    ),
  ];

  async getModules() {
    const retrievedModules = await getModulesFromDatabase()
      .then((modules: any) => {
        //console.log("getModules2",modules.success)
        const dbMods = modules.success;

        let retModules: interfaces.ModuleTypes[] = [];

        dbMods.map((mod: any) => {
          const mods: interfaces.ModuleTypes = {
            id: mod.id,
            type: mod.type,
            name: mod.name,
            outputs: mod.outputs,
            nextModule: mod.nextModule,
            parentModule: mod.parentModule,
            inputs: mod.inputs,
            busConsumption: mod.busConsumption,
            adresses: mod.adresses,
            din: mod.din,
            icon: mod.icon,
            prijs: mod.prijs,
            offCat: mod.offCat,
            sa: mod.sa,
            pakket: mod.pakket,
            //totoutp: mod.totoutp,
          };
          retModules.push(mods);
        });

        sessionStorage.setItem("DbModules", JSON.stringify(retModules));
        return retModules;
      });
  }

  async checkMod() {
    this.rows.pop();

    const dbMods: ModuleTypes[] = JSON.parse(
      sessionStorage.getItem("DbModules") || "{}"
    );
    if (dbMods) {
      dbMods.map((mod: ModuleTypes) => {
        this.rows.push(
          this.createData(
            mod.id,
            mod.type,
            mod.name,
            mod.outputs,
            mod.nextModule,
            mod.parentModule,
            mod.inputs,
            mod.busConsumption,
            mod.adresses,
            mod.din,
            mod.icon,
            mod.prijs,
            mod.offCat,
          )
        );
      });
    }
  }

  async updateModule(id:number,type:string, outputs:number, nextModule:number, parentModule:number, inputs:number, busConsumption:number, adresses: number,din:number, cat:number) {
    const created = await UpdateModule(
      id,
      type,
      outputs,
      nextModule,
      parentModule,
      inputs,
      busConsumption,
      adresses,
    din,
    cat
  );
  console.log("rp: ", created)
  }

  update = ( id:any) => {
//let row = event.target.parentNode.parentNode 
console.log(id)
let type = (document.getElementById("type-"+id) as HTMLInputElement).value;
let outputs = (document.getElementById("outputs-"+id) as HTMLInputElement).value;
let nextModule = (document.getElementById("nextModule-"+id) as HTMLInputElement).value;
let parentModule = (document.getElementById("parentModule-"+id) as HTMLInputElement).value;
let inputs = (document.getElementById("inputs-"+id) as HTMLInputElement).value;
let busConsumption = (document.getElementById("busConsumption-"+id) as HTMLInputElement).value;
let adresses = (document.getElementById("adresses-"+id) as HTMLInputElement).value;
let din = (document.getElementById("din-"+id) as HTMLInputElement).value;
let cat = (document.getElementById("cat-"+id) as HTMLInputElement).value;


console.log(type, "-", outputs)
this.updateModule(id, type, parseInt(outputs), parseInt(nextModule), parseInt(parentModule), parseInt(inputs), parseInt(busConsumption), parseInt(adresses), parseInt(din), parseFloat(cat))


  }

  render() {
    this.checkMod();
    return (
      <>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Outputs</TableCell>
                <TableCell>Next mod</TableCell>
                <TableCell>Parent mod</TableCell>
                <TableCell>Inputs</TableCell>
                <TableCell>Buscons</TableCell>
                <TableCell>Adresses</TableCell>
                <TableCell>DIN</TableCell>
                <TableCell>Icon</TableCell>
                <TableCell>Prijs</TableCell>
                <TableCell>Cat</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.rows.map((row) => (
                <TableRow
                  key={row.id}
                  
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{row.id}</TableCell>
                  <TableCell><input type="text" size={10} defaultValue={row.type} id={"type-"+ row.id} /> </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell><input type="text" size={2} defaultValue={row.outputs} id={"outputs-"+ row.id}/></TableCell>
                  <TableCell><input type="text" size={2} defaultValue={row.nextModule} id={"nextModule-"+ row.id}/></TableCell>
                  <TableCell><input type="text" size={2} defaultValue={row.parentModule} id={"parentModule-"+ row.id}/></TableCell>
                  <TableCell><input type="text" size={2} defaultValue={row.inputs} id={"inputs-"+ row.id}/></TableCell>
                  <TableCell><input type="text" size={2} defaultValue={row.busConsumption} id={"busConsumption-"+ row.id}/></TableCell>
                  <TableCell><input type="text" size={2} defaultValue={row.adresses} id={"adresses-"+ row.id}/></TableCell>
                  <TableCell><input type="text" size={2} defaultValue={row.din} id={"din-"+ row.id}/></TableCell>
                  <TableCell>{row.icon}</TableCell>
                  <TableCell>{row.prijs}</TableCell>
                  <TableCell><input type="text" size={2} defaultValue={row.offCat} id={"cat-"+ row.id}/></TableCell>
                  <TableCell><button onClick={() => {  this.update(row.id) }}>Update</button></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}

export default AdminProductTable;
