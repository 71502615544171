import * as myTreeIcons from "../images/icons/treeicons";
interface kamerItem {
  id: string;
  name: string;
  icon: any;
  expanded: boolean;
  children?: kamerItem[];
}
export interface dbItem {
  nodeid: string;
  label: string;
  type: string;
  parent: string;
  typeIcon: string;
  moduletype: string;
  outputOptions: JSON;
}

export const ChangeTreeItemIcon = (
  itemId: string,
  icon: string,
  woningTree: any,
  woningDb: any
) => {
  // Update name in woningObject (for tree)
  let woningObject = woningTree;
  const loopWoning = (nodes: kamerItem) =>
    nodes.children?.map((item) => {
      if (item.id === itemId) {
        item.icon = myTreeIcons.getTreeIcon2(icon, icon);
      }
      if (item.children) {
        loopWoning(item);
      }
    });

  loopWoning(woningObject);

  // Update name in woningDb (for project)
  let found2: dbItem = woningDb.find(
    (element: dbItem) => element.nodeid === itemId
  )!;
  if (found2 !== undefined) {
    found2.typeIcon = icon;
    found2.type = icon;
  }

  //debugger
  return woningObject;
};

export const RemoveTreeItem = (
  itemId: string,
  woningTree: any,
  woningDb: any
) => {
  let woningObject = woningTree;
  const loopWoning = (nodes: kamerItem) =>
    nodes.children?.map((item) => {
      var newwoningObject: any;
      if (item.children) {
        newwoningObject = item.children.filter(function (el: kamerItem) {
          return el.id != itemId;
        });
        item.children = newwoningObject;
        loopWoning(item);
      }
    });

  loopWoning(woningObject);

  var newwoningObject: any = woningTree.children.filter(function (
    el: kamerItem
  ) {
    return el.id != itemId;
  });

  woningObject.children = newwoningObject;

  let tempWoningDb = woningDb;
  var newWoningDb = tempWoningDb.filter(function (el: any) {
    return el.nodeid === itemId;
  });

  debugger
  newWoningDb.forEach((f:any) => tempWoningDb.splice(tempWoningDb.findIndex((e:any) => e.nodeid === f.nodeid),1));

  
  woningDb = newWoningDb;

  return woningObject;
};


export const ChangeTreeItemName = (
  itemId: string,
  newName: string,
  woningTree: any,
  woningDb: any
) => {
  // Update name in woningObject (for tree)
  let woningObject = woningTree;

  const loopWoning = (nodes: kamerItem) =>
    nodes.children?.map((item) => {
      if (item.id === itemId) {
        item.name = newName;
      }
      if (item.children) {
        loopWoning(item);
      }
    });

  loopWoning(woningObject);

  // Update woningDb (for)
  let found2: dbItem = woningDb.find(
    (element: dbItem) => element.nodeid === itemId
  )!;
  if (found2 !== undefined) {
    found2.label = newName;
  }

  return woningObject;
};

export const AddItemToWoningTree = (
  dbItemToAdd: dbItem,
  parentId: string,
  woningTree: any
) => {
  //console.log("A")
  let dbIcon = myTreeIcons.getTreeIcon2(dbItemToAdd.typeIcon, dbItemToAdd.type);
  let woningObject = woningTree;

  let dataForTree = {
    id: dbItemToAdd.nodeid,
    name: dbItemToAdd.label,
    children: [],
    icon: dbIcon,
    expanded: true,
  };

  

  if (woningObject.id === parentId) {
    let base: kamerItem = woningObject;
    base.children?.push(dataForTree);
  } else {
    const loopWoning = (nodes: kamerItem) =>
      nodes.children?.map((item) => {
        if (item.id === parentId) {
          item.children?.push(dataForTree);
        } else {
          loopWoning(item);
        }
      });
    loopWoning(woningObject);
  }
  
  return woningObject;
};


export const   ChangeSelectedFloor = (woningSlectedNodeId:any, floorid: any, type: string, label: string) => {
  let floor = woningSlectedNodeId;
  floor.id = floorid;
  floor.type = type;
  floor.label = label;

  return floor;
};