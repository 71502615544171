export const WoningIcon = () => {
    return (
        <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" >
        <polygon
          style={{
            stroke: "rgb(242, 118, 17)",
            fill: "none",
            strokeWidth: 18,
          }}
          points="62.912 52.134 62.912 465.177 199.934 465.177 198.617 266.889 442.358 267.548 441.7 51.475"
        />
      </svg>
    )
}