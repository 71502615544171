import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


interface MyState {
  }

  interface MyProps {
    visible: string;
    onDataChange: (t:any) => any;
  }

class Options extends Component<MyProps, MyState>{
    constructor(props:any) {
      super(props);
    }

    vstate = {settings:"hidden"}

    handleClose = () => {
        this.vstate.settings = "hidden"
        this.props.onDataChange(this.vstate)
    }

    render() {
        return (
            <Modal show={this.props.visible === "options" ? true : false} >
                <Modal.Header closeButton onClick={this.handleClose}>
                <Modal.Title><h1> Options</h1></Modal.Title>
                </Modal.Header>
                <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={this.handleClose}>
                    Save Changes
                </Button>
                </Modal.Footer>
            </Modal>
        )
      }
}

export default Options;