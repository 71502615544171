import React, { Component } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

interface klant {
  id: string;
  naam: string;
  voornaam: string;
  firma: string;
  straat: string;
  huisnummer: string;
  postcode: string;
  gemeente: string;
  land: string;
  telefoon: string;
  btw: string;
}

interface MyState {
    id: string;
  klant: klant;
  naam: string;
  voornaam: string;
  firma: string;
  straat: string;
  huisnummer: string;
  postcode: string;
  gemeente: string;
  land: string;
  telefoon: string;
  btw: string;
}

interface MyProps {
  onKlantUpdate: (klant: klant) => any;
  klant: klant;
}

class Klant extends Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
        id: "",
        naam: "",
        voornaam: "",
        firma: "",
        straat: "",
        huisnummer: "",
        postcode: "",
        gemeente: "",
        land: "",
        telefoon: "",
        btw: "",
      klant: {
        id: "",
        naam: "",
        voornaam: "",
        firma: "",
        straat: "",
        huisnummer: "",
        postcode: "",
        gemeente: "",
        land: "",
        telefoon: "",
        btw: "",
      },
    };
  }

  submitHandler = (e: any) => {
    // Clear state
    this.setState({id: ""})
    this.setState({naam: ""})
    this.setState({voornaam: ""})
    this.setState({firma: ""})
    this.setState({straat: ""})
    this.setState({huisnummer: ""})
    this.setState({postcode: ""})
    this.setState({gemeente: ""})
    this.setState({land: ""})
    this.setState({telefoon: ""})
    this.setState({btw: ""})
    
    e.preventDefault();

    const id = e.currentTarget.id.value;
    const naam = e.currentTarget.naam.value;
    const voornaam = e.currentTarget.voornaam.value;
    const firma = e.currentTarget.firma.value;
    const straat = e.currentTarget.straat.value;
    const huisnr = e.currentTarget.huisnummer.value;
    const postcode = e.currentTarget.postcode.value;
    const gemeente = e.currentTarget.gemeente.value;
    const land = e.currentTarget.land.value;
    const telefoon = e.currentTarget.telefoon.value;
    const btwnr = e.currentTarget.btw.value;

    const klant: klant = {
      id: id,
      naam: naam,
      voornaam: voornaam,
      firma: firma,
      straat: straat,
      huisnummer: huisnr,
      postcode: postcode,
      gemeente: gemeente,
      land: land,
      telefoon: telefoon,
      btw: btwnr,
    };

    this.props.onKlantUpdate(klant);
  };

  setNaam = (e: any) => {
    this.setState({ naam: e.target.value });
  };

  setVoorNaam = (e: any) => {
    this.setState({ voornaam: e.target.value });
  };

  setFirma = (e: any) => {
    this.setState({ firma: e.target.value });
  };

  setStraat = (e: any) => {
    this.setState({ straat: e.target.value });
  };

  setHuisnr = (e: any) => {
    this.setState({ huisnummer: e.target.value });
  };

  setPostcode = (e: any) => {
    this.setState({ postcode: e.target.value });
  };

  setGemeente = (e: any) => {
    this.setState({ gemeente: e.target.value });
  };

  setLand = (e: any) => {
    this.setState({ land: e.target.value });
  };

  setTelefoon = (e: any) => {
    this.setState({ telefoon: e.target.value });
  };

  setBtw = (e: any) => {
    this.setState({ btw: e.target.value });
  };

  render() {
    //this.setState({naam: this.props.klant.naam})
    let naam = this.props.klant.naam;
    return (
      <>
        <Form onSubmit={this.submitHandler}>
          <Form.Control
            type="hidden"
            value={this.state.id || this.props.klant.id}
            name="id"
          />

          <Row>
            <Col>
              <Form.Label className="customer-formLabel">Naam</Form.Label>
              <Form.Control
                type="text"
                value={this.state.naam || this.props.klant.naam}
                name="naam"
                className="customer-form"
                onChange={this.setNaam}
              />
              <Form.Text className="customer-form"></Form.Text>
            </Col>

            <Col>
              <Form.Label className="customer-formLabel">Voornaam</Form.Label>
              <Form.Control
                type="text"
                value={this.state.voornaam || this.props.klant.voornaam}
                name="voornaam"
                className="customer-form"
                onChange={this.setVoorNaam}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label className="customer-formLabel">Firma</Form.Label>
              <Form.Control
                type="text"
                value={this.state.firma || this.props.klant.firma}
                name="firma"
                className="customer-form"
                onChange={this.setFirma}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label className="customer-formLabel">Straat</Form.Label>
              <Form.Control
                type="text"
                value={this.state.straat || this.props.klant.straat}
                name="straat"
                className="customer-form"
                onChange={this.setStraat}
                //style={{width: "20x;"}}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Col>
            <Col>
              <Form.Label className="customer-formLabel">Huisnummer</Form.Label>
              <Form.Control
                type="text"
                value={this.state.huisnummer || this.props.klant.huisnummer}
                name="huisnummer"
                className="customer-form"
                onChange={this.setHuisnr}
                //style={{width: "20x;"}}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Label className="customer-formLabel">Postcode</Form.Label>
              <Form.Control
                type="text"
                value={this.state.postcode || this.props.klant.postcode}
                name="postcode"
                className="customer-form"
                onChange={this.setPostcode}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Col>
            <Col>
              <Form.Label className="customer-formLabel">Gemeente</Form.Label>
              <Form.Control
                type="text"
                value={this.state.gemeente || this.props.klant.gemeente}
                name="gemeente"
                className="customer-form"
                onChange={this.setGemeente}
              />
              <Form.Text className="text-muted"></Form.Text>
            </Col>
          </Row>

          <Form.Label className="customer-formLabel">Land</Form.Label>
          <Form.Control
            type="text"
            value={this.state.land || this.props.klant.land}
            name="land"
            className="customer-form"
            onChange={this.setLand}
          />
          <Form.Text className="text-muted"></Form.Text>

          <Form.Label className="customer-formLabel">Telefoon</Form.Label>
          <Form.Control
            type="text"
            value={this.state.telefoon || this.props.klant.telefoon}
            name="telefoon"
            className="customer-form"
            onChange={this.setTelefoon}
          />
          <Form.Text className="text-muted"></Form.Text>

          <Form.Label className="customer-formLabel">BTW nr</Form.Label>
          <Form.Control
            type="text"
            value={this.state.btw || this.props.klant.btw}
            name="btw"
            className="customer-form"
            onChange={this.setBtw}
          />
          <Form.Text className="text-muted"></Form.Text>

          <Button variant="primary" type="submit">
            Bewaar
          </Button>
        </Form>
      </>
    );
  }
}

export default Klant;
